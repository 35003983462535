<template>
  <div class="progress-container d-none d-md-block">
    <b-progress v-if="step === 1" value="18.1" max="100"></b-progress>
    <b-progress v-if="step === 2" value="52.25" max="100"></b-progress>
    <b-progress v-if="step === 3" value="86.5" max="100"></b-progress>
    <b-progress v-if="step === 4" value="100" max="100"></b-progress>

    <div class="row">
      <div class="col-12">
        <div id="process_bar" class="row step">
          <div id="div1" class="processbutton-container col-md-4">
            <div v-if="step === 1" class="process_button button_1 activestep"></div>
            <router-link v-if="step === 2 || step === 3" to="/step1">
              <div class="process_button button_1 done"></div>
            </router-link>
            <div v-if="step === 4" class="process_button button_1 done"></div>
            <div class="button_text">{{ $t("locations-label") }}</div>
          </div>

          <div class="processbutton-container col-md-4">
            <div v-if="step === 1" class="process_button button_2"></div>
            <div v-if="step === 2" class="process_button button_2 activestep"></div>
            <router-link v-if="step === 3" to="/step2">
              <div class="process_button button_2 done"></div>
            </router-link>
            <div v-if="step === 4" class="process_button button_2 done"></div>
            <div class="button_text">{{ $t("dealer-detals-label") }}</div>
          </div>

          <div class="processbutton-container col-md-4">
            <div v-if="step === 1 || step === 2" class="process_button button_3"></div>
            <div v-if="step === 3" class="process_button button_3 activestep"></div>
            <div v-if="step === 4" class="process_button button_3 done"></div>
            <div class="button_text">{{ $t("review-submit-label") }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props: ['step'],
  data() {
    return {
    };
  },
  computed: {
  },
};
</script>

<style scoped>
.processbutton-container {
    padding-top: 20px;
}
.progress-container {
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}
</style>
