import axios from 'axios';
const authService = require("../lib/services/auth.service");
import router from '../router';

export default {
    initAuthInterceptor: (store) => {
        let isRefreshInProgress = false;
        let failedQueue = [];

        // Add a request interceptor
        axios.interceptors.request.use(
            function (config) {
                if(store.getters['auth/getAccessToken']){
                    config.headers['x-custom-authorization'] = 'Bearer ' + store.getters['auth/getAccessToken'];
                }
                return config;
            }, function (error) {
                // Do something with request error
                return Promise.reject(error);
            });

        const processQueue = (error, token = null) => {
            failedQueue.forEach(request => {
                if (error) {
                    request.reject(error);
                } else {
                    request.resolve(token);
                }
            });
            failedQueue = [];
        }

        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            const status = error.response ? error.response.status : null;
            const originalRequest = error.config;
            if (status === 401 && !originalRequest._retry && !error.config.url.includes('login')) {
                if (store.state.auth.isLoggingIn){
                    return Promise.reject('Username or password is incorrect');
                } else if (error.config.url.includes('refresh-token')) {
                    store.dispatch('auth/logout');
                    router.push({ name: 'login' });
                }

                if (isRefreshInProgress) {
                  return new Promise(
                      function(resolve, reject) {
                          failedQueue.push({resolve, reject})
                      }).then(token => {
                          originalRequest.headers['x-custom-authorization'] = 'Bearer ' + token;
                          return axios(originalRequest);
                      }).catch(err => {
                          return err
                      })
                }

                // Admin doesn't have refresh token
                if(!store.getters['auth/isAdmin']) {
                    originalRequest._retry = true;
                    isRefreshInProgress = true;

                    return new Promise(function (resolve, reject) {
                        authService.refreshAccessToken(store)
                        .then(()=>{
                        //repeat requests after refresh with new header
                        processQueue(null, store.getters['auth/getAccessToken']);
                        originalRequest.headers['x-custom-authorization'] = 'Bearer ' + store.getters['auth/getAccessToken'];
                        resolve(axios(originalRequest));
                        })
                        .catch((e)=>{
                        processQueue(e, null);
                        reject(e);
                        store.dispatch('auth/logout');
                        router.push({ name: 'login' });
                        })
                        .then(() => {
                        isRefreshInProgress = false;
                        });
                    })
                } else {
                    store.dispatch('auth/logout');
                    router.push({ name: 'login' });
                }
                
            } else if (status === 403) {
                return Promise.reject('You do not have permission to look this page');
            } else {
                return Promise.reject(error);
            }
        });
    }
}