<template>
  <div>
    <progress-bar :step="2"></progress-bar>

    <div class="row">
      <div class="details-container col-md-12">
        <h1 style="margin: 0px">{{$t('dealer-detals-label')}}</h1>
        <div class="cust-number" v-if="!allDealersUpdate">{{$t('customer-number')}}: {{dealerDetails.customerNumber}}</div>
        <div class="cust-number" v-if="allDealersUpdate">{{$t('common-owner-number')}}: {{dealerDetails.commonOwner}}</div>
        <div class="section-divider"></div>

        <!-- ALL DEALERS UPDATE - Don't show dealer information panel if there is no common owner record in DB -->
        <ExpansionPanel v-if="!allDealersUpdate || (allDealersUpdate && dealerDetails.commonOwner === dealerDetails.customerNumber)">
            <template slot="title">{{$t('dealer-information-label')}}</template>
            <template slot="content">
                <div class="alert alert-warning" role="alert">{{$t('dealer-section-incorrect-hint')}}.</div>
                <b-row class="store-name-phone-container">
                    <b-col md="6">
                        <label for="storename">{{$t('store-name')}}</label>
                        <b-form-input type="text" class="form-control" name="storename" v-model="dealerDetails.common.storeName" readonly />
                    </b-col>
                    <b-col md="6">
                        <label for="phone">{{$t('phone-number')}}</label>
                        <b-form-input type="text" class="form-control" name="phone" v-model="dealerDetails.common.telephoneNumber" readonly />
                    </b-col>
                </b-row>

                <b-row class="gy-form-group">
                    <b-col>
                        <b-form-checkbox class="checkbox-center" name="display-on-gy-com" onclick="return false;" v-model="dealerDetails.common.displayOnConsumer">{{$t('display-on-consumer')}}</b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row class="gy-form-group">
                    <b-col>
                        <b-form-checkbox class="checkbox-center" name="display-on-gy-truck" onclick="return false;" v-model="dealerDetails.common.displayOnCommercial">{{$t('display-on-commercial')}}</b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-checkbox class="checkbox-center" name="display-on-gy-otr" onclick="return false;" v-model="dealerDetails.common.displayOnOtr">{{$t('display-on-otr')}}</b-form-checkbox>
                    </b-col>
                </b-row>  
            </template>
        </ExpansionPanel>

        <ExpansionPanel>
            <template slot="title">{{$t('website-social-label')}}</template>
            <template slot="content">
                <b-row class="gy-form-group">
                    <b-col md="6">
                        <label for="weburl">{{$t('website-url-label')}}</label>
                        <b-form-input type="text" class="form-control" name="weburl" v-model="dealerDetails.social.website" />
                    </b-col>
                </b-row>
                <b-row><b-col><div class="interior-header">{{$t('social-url-label')}}</div></b-col></b-row>
                <b-row class="gy-form-group">
                    <b-col md="4">
                        <label for="facebook">
                            <img src="../assets/images/icon-fb.png"> {{$t('facebook-label')}}
                        </label>
                        <b-form-input type="text" class="form-control" name="facebook" v-model="dealerDetails.social.fbUrl" :disabled="dealerDetails.social.fbIsNotUsed" />
                    </b-col>
                    <b-col md="2" class="not-used">
                        <b-form-checkbox class="checkbox-center" name="fbNotUsed" v-model="dealerDetails.social.fbIsNotUsed">
                            {{$t('not-used-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4">
                        <label for="facebook">
                            <img src="../assets/images/icon-instagram.png"> {{$t('instagram-label')}}
                        </label>
                        <b-form-input type="text" class="form-control" name="instagram" v-model="dealerDetails.social.instUrl" :disabled="dealerDetails.social.instIsNotUsed" />
                    </b-col>
                    <b-col md="2" class="not-used">
                        <b-form-checkbox class="checkbox-center" name="instIsNotUsed" v-model="dealerDetails.social.instIsNotUsed">
                            {{$t('not-used-label')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row class="gy-form-group">
                    <b-col md="4">
                        <label for="gplus">
                            <img src="../assets/images/icon-gplus.png" height="25px" width="25px"> {{$t('google-plus-label')}}
                        </label>
                        <b-form-input type="text" class="form-control" name="gplus" v-model="dealerDetails.social.gUrl" :disabled="dealerDetails.social.gIsNotUsed" />
                    </b-col>
                    <b-col md="2" class="not-used">
                        <b-form-checkbox class="checkbox-center" name="gIsNotUsed" v-model="dealerDetails.social.gIsNotUsed">
                            {{$t('not-used-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4">
                        <label for="twitter">
                            <img src="../assets/images/icon-twitter.png"> {{$t('twitter-label')}}
                        </label>
                        <b-form-input type="text" class="form-control" name="twitter" v-model="dealerDetails.social.twitterUrl" :disabled="dealerDetails.social.twitterIsNotUsed" />
                    </b-col>
                    <b-col md="2" class="not-used">
                        <b-form-checkbox class="checkbox-center" name="twitterIsNotUsed" v-model="dealerDetails.social.twitterIsNotUsed">
                            {{$t('not-used-label')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <label for="yelp">
                            <img src="../assets/images/icon-yelp.png"> {{$t('yelp-label')}}
                        </label>
                        <b-form-input type="text" class="form-control" name="yelp" v-model="dealerDetails.social.yelpUrl" :disabled="dealerDetails.social.yelpIsNotUsed" />
                    </b-col>
                    <b-col md="2" class="not-used">
                        <b-form-checkbox class="checkbox-center" name="yelpIsNotUsed" v-model="dealerDetails.social.yelpIsNotUsed">
                            {{$t('not-used-label')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </template>
        </ExpansionPanel>

        <ExpansionPanel>
            <template slot="title">{{$t('store-hours-dates-closed-label')}}</template>
            <template slot="content">
                <div class="interior-header">{{$t('regular-store-hours')}}</div>
                <p>{{$t('set-store-hours-dates-closed-label')}}.</p>

                <table>
                    <tbody>
                        <!--- MONDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.monIsActive">{{$t('monday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.monIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.monIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.monIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.monStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.monStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.monEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.monStartTime, dealerDetails.storeHours.monStartAMPM,
                                                dealerDetails.storeHours.monEndTime, dealerDetails.storeHours.monEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.monEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.monHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.monAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.monEndTime, dealerDetails.storeHours.monEndAMPM,
                                                    dealerDetails.storeHours.monAddStartTime, dealerDetails.storeHours.monAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.monAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.monAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.monAddStartTime, dealerDetails.storeHours.monAddStartAMPM,
                                                    dealerDetails.storeHours.monAddEndTime, dealerDetails.storeHours.monAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.monAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.monHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.monHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.monHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!--- TUESDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.tueIsActive">{{$t('tuesday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.tueIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.tueIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.tueIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.tueStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.tueStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.tueEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.tueStartTime, dealerDetails.storeHours.tueStartAMPM,
                                                dealerDetails.storeHours.tueEndTime, dealerDetails.storeHours.tueEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.tueEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.tueHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.tueAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.tueEndTime, dealerDetails.storeHours.tueEndAMPM,
                                                    dealerDetails.storeHours.tueAddStartTime, dealerDetails.storeHours.tueAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.tueAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.tueAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.tueAddStartTime, dealerDetails.storeHours.tueAddStartAMPM,
                                                    dealerDetails.storeHours.tueAddEndTime, dealerDetails.storeHours.tueAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.tueAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.tueHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.tueHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.tueHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!--- WEDNESDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.wedIsActive">{{$t('wednesday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.wedIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.wedIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.wedIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.wedStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.wedStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.wedEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.wedStartTime, dealerDetails.storeHours.wedStartAMPM,
                                                dealerDetails.storeHours.wedEndTime, dealerDetails.storeHours.wedEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.wedEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.wedHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.wedAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.wedEndTime, dealerDetails.storeHours.wedEndAMPM,
                                                    dealerDetails.storeHours.wedAddStartTime, dealerDetails.storeHours.wedAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.wedAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.wedAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.wedAddStartTime, dealerDetails.storeHours.wedAddStartAMPM,
                                                    dealerDetails.storeHours.wedAddEndTime, dealerDetails.storeHours.wedAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.wedAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.wedHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.wedHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.wedHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!--- THURSDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.thuIsActive">{{$t('thursday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.thuIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.thuIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.thuIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.thuStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.thuStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.thuEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.thuStartTime, dealerDetails.storeHours.thuStartAMPM,
                                                dealerDetails.storeHours.thuEndTime, dealerDetails.storeHours.thuEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.thuEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.thuHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.thuAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.thuEndTime, dealerDetails.storeHours.thuEndAMPM,
                                                    dealerDetails.storeHours.thuAddStartTime, dealerDetails.storeHours.thuAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.thuAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.thuAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.thuAddStartTime, dealerDetails.storeHours.thuAddStartAMPM,
                                                    dealerDetails.storeHours.thuAddEndTime, dealerDetails.storeHours.thuAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.thuAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.thuHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.thuHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.thuHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!--- FRIDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.friIsActive">{{$t('friday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.friIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.friIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.friIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.friStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.friStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.friEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.friStartTime, dealerDetails.storeHours.friStartAMPM,
                                                dealerDetails.storeHours.friEndTime, dealerDetails.storeHours.friEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.friEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.friHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.friAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.friEndTime, dealerDetails.storeHours.friEndAMPM,
                                                    dealerDetails.storeHours.friAddStartTime, dealerDetails.storeHours.friAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.friAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.friAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.friAddStartTime, dealerDetails.storeHours.friAddStartAMPM,
                                                    dealerDetails.storeHours.friAddEndTime, dealerDetails.storeHours.friAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.friAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.friHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.friHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.friHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!--- SATURDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.satIsActive">{{$t('saturday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.satIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.satIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.satIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.satStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.satStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.satEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.satStartTime, dealerDetails.storeHours.satStartAMPM,
                                                dealerDetails.storeHours.satEndTime, dealerDetails.storeHours.satEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.satEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.satHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.satAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.satEndTime, dealerDetails.storeHours.satEndAMPM,
                                                    dealerDetails.storeHours.satAddStartTime, dealerDetails.storeHours.satAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.satAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.satAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.satAddStartTime, dealerDetails.storeHours.satAddStartAMPM,
                                                    dealerDetails.storeHours.satAddEndTime, dealerDetails.storeHours.satAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.satAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.satHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.satHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.satHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                        <!--- SUNDAY --->
                        <tr>
                            <td>
                                <b-form-checkbox class="checkbox-center" v-model="dealerDetails.storeHours.sunIsActive">{{$t('sunday')}}</b-form-checkbox>
                            </td>
                            <template v-if="dealerDetails.storeHours.sunIsActive">
                                <td>
                                    <b-form-checkbox class="checkbox-center day-closed" v-model="dealerDetails.storeHours.sunIsClosed">{{$t('closed')}}</b-form-checkbox>
                                </td>
                                <template v-if="!dealerDetails.storeHours.sunIsClosed">
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.sunStartTime" :options="timeValues" size="sm"></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.sunStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <td class="to-column">{{$t('to')}}</td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.sunEndTime" :options="timeValues" size="sm"
                                            v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.sunStartTime, dealerDetails.storeHours.sunStartAMPM,
                                                dealerDetails.storeHours.sunEndTime, dealerDetails.storeHours.sunEndAMPM)}" 
                                        ></b-form-select>
                                    </td>
                                    <td>
                                        <b-form-select v-model="dealerDetails.storeHours.sunEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                    </td>
                                    <template v-if="dealerDetails.storeHours.sunHasAddHours">
                                        <td class="text-center text-uppercase">{{$t('and')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.sunAddStartTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.sunEndTime, dealerDetails.storeHours.sunEndAMPM,
                                                    dealerDetails.storeHours.sunAddStartTime, dealerDetails.storeHours.sunAddStartAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.sunAddStartAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td class="to-column">{{$t('to')}}</td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.sunAddEndTime" :options="timeValues" size="sm"
                                                v-bind:class="{ 'time-error':!isIntervalValid(dealerDetails.storeHours.sunAddStartTime, dealerDetails.storeHours.sunAddStartAMPM,
                                                    dealerDetails.storeHours.sunAddEndTime, dealerDetails.storeHours.sunAddEndAMPM)}"
                                            ></b-form-select>
                                        </td>
                                        <td>
                                            <b-form-select v-model="dealerDetails.storeHours.sunAddEndAMPM" :options="ampmValues" size="sm"></b-form-select>
                                        </td>
                                        <td>
                                            <div class="col-md-2 remove-container">
                                                <button class="btn-remove" v-on:click="dealerDetails.storeHours.sunHasAddHours = false">
                                                    <b-icon icon="x" variant="danger" />
                                                </button>
                                            </div>
                                        </td>
                                    </template>
                                    <template v-if="!dealerDetails.storeHours.sunHasAddHours">
                                        <td>
                                            <a class="add-new-hours" v-on:click="dealerDetails.storeHours.sunHasAddHours = true">
                                                <b-icon icon="plus" /> {{$t('add-hours')}}
                                            </a>
                                        </td>
                                    </template>
                                </template>
                            </template>
                        </tr>
                    </tbody>
                </table>
                
                <b-row class="dates-closed">
                    <b-col md="4">
                        <div class="interior-header">{{$t('closed-dates-store-label')}}</div>
                        <b-row v-if="undeletedClosedDates.length === 0">
                            <b-col md="8">
                                <b-form-datepicker 
                                    v-model="initialClosedDate" 
                                    placeholder="Select Date" 
                                    locale="en-GB" 
                                    :date-format-options="{ day: 'numeric' , month: 'numeric', year: 'numeric' }" 
                                    class="mb-2" size="sm"
                                ></b-form-datepicker>
                            </b-col>
                        </b-row>
                        <b-row v-for="(date,index) in dealerDetails.closedDates" v-bind:key="`date-${index}`">
                            <template v-if="!date.isDeleted">
                                <b-col md="8">
                                    <b-form-datepicker 
                                        v-model="date.closedDate" 
                                        :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                                        locale="en-GB" 
                                        class="mb-2" size="sm"
                                        ></b-form-datepicker>
                                </b-col>
                                <b-col md="2" class="remove-container">
                                    <button class="btn-remove" v-on:click="date.isDeleted = 1">
                                        <b-icon icon="x" variant="danger" />
                                    </button>
                                </b-col>
                            </template>
                        </b-row>
                        <b-row class="add-new-container" v-if="undeletedClosedDates.length !== 0">
                            <b-col>
                                <a class="add-new-link" v-on:click="addClosedDate">
                                    <b-icon icon="plus" /> {{$t('add-another-date-label')}}
                                </a>
                            </b-col>
                        </b-row>
    
                    </b-col>
                </b-row>
            </template>
        </ExpansionPanel>

        <ExpansionPanel>
            <template slot="title">{{$t('store-offerings-label')}}</template>
            <template slot="content">
                <b-row>
                    <b-col md="4">
                        <div class="interior-header">{{$t('amenities-offerings-label')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="freeGenCheckup" v-model="dealerDetails.offerings.freeGenCheckup" :value="1" :unchecked-value="0">
                            {{$t('free-general-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="vipClientCard" v-model="dealerDetails.offerings.vipClientCard" :value="1" :unchecked-value="0">
                            {{$t('vip-client-card-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="snacks" v-model="dealerDetails.offerings.snacks" :value="1" :unchecked-value="0">
                            {{$t('snacks-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="beverages" v-model="dealerDetails.offerings.beverages" :value="1" :unchecked-value="0">
                            {{$t('beverages-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="coffeeStation" v-model="dealerDetails.offerings.coffeeStation" :value="1" :unchecked-value="0">
                            {{$t('coffee-station-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="pickUpSvc" v-model="dealerDetails.offerings.pickUpSvc" :value="1" :unchecked-value="0">
                            {{$t('vehicle-service-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="wifi" v-model="dealerDetails.offerings.wifi" :value="1" :unchecked-value="0">
                            {{$t('free-wifi-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4">
                        <div class="interior-header">{{$t('credit-cards-accepted-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="crdMastercard" v-model="dealerDetails.offerings.crdMastercard" :value="1" :unchecked-value="0">
                            <img src="../assets/images/creditcards/mastercard.png" alt="Mastercard credit card icon"> MasterCard
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="crdVisa" v-model="dealerDetails.offerings.crdVisa" :value="1" :unchecked-value="0">
                            <img src="../assets/images/creditcards/visa.png" alt="Visa credit card icon"> Visa
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="crdAmrcnExpress" v-model="dealerDetails.offerings.crdAmrcnExpress" :value="1" :unchecked-value="0">
                            <img src="../assets/images/creditcards/american.png" alt="American Express credit card icon"> American Express
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="crdDiscover" v-model="dealerDetails.offerings.crdDiscover" :value="1" :unchecked-value="0">
                            <img src="../assets/images/creditcards/discover.png" alt="Discover credit card icon"> Discover
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </template>
        </ExpansionPanel>

        <ExpansionPanel v-if="!allDealersUpdate">
            <template slot="title">{{$t('location-on-map-title')}}</template>
            <template slot="content">
                <b-row>
                    <b-col md="6">
                        <p>
                            <strong>{{$t('street-address')}}:</strong> {{dealerDetails.location.streetAddress}}, {{dealerDetails.location.city}}
                        </p>
                        <div class="alert alert-warning" role="alert">{{$t('dealer-information-incorrect-hint')}}</div>

                        <p>{{$t('street-address-hint')}}.</p>

                        <div class="gy-form-group">
                            <label for="lat">LAT</label>
                            <b-form-input type="number" class="form-control" name="lat" v-model.number="dealerDetails.location.lat" />
                        </div>

                        <label for="lng">LONG</label>
                        <b-form-input type="number" class="form-control" name="lng" v-model.number="dealerDetails.location.lng" />

                        <p>
                            <a class="reset-location" v-on:click="resetLocation">
                                <b-icon icon="arrow-repeat"/> {{$t('reset-map-label')}}
                            </a>
                        </p>
                        
                    </b-col>
                    <b-col md="6">
                        <GmapMap v-if="dealerDetails.location.lat"
                            :center="{lat:dealerDetails.location.lat, lng:dealerDetails.location.lng}"
                            :zoom="15"
                            style="width:100%;  height: 400px;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false
                            }"
                            @click="updateLatLng"
                            >
                            <GmapMarker
                                :position="{lat:parseFloat(dealerDetails.location.lat), lng:parseFloat(dealerDetails.location.lng)}"
                                :clickable="true"
                                :draggable="true"
                                @dragend="updateLatLng"
                            />
                        </GmapMap>
                    </b-col>
                </b-row>
            </template>
        </ExpansionPanel>

        <ExpansionPanel>
            <template slot="title">{{$t('services')}}</template>
            <template slot="content">
                <b-row>
                    <b-col md="12">
                        <div class="interior-header">{{$t('automotive-tire-services-label')}}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="airCond" v-model="dealerDetails.services.airCond" :value="1" :unchecked-value="0">
                            {{$t('air-conditioning-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="batteries" v-model="dealerDetails.services.batteries" :value="1" :unchecked-value="0">
                            {{$t('batteries-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="beltsHoses" v-model="dealerDetails.services.beltsHoses" :value="1" :unchecked-value="0">
                            {{$t('belts-hoses')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="sysRepairs" v-model="dealerDetails.services.sysRepairs" :value="1" :unchecked-value="0">
                            {{$t('brake-systems-repair-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="drivelines" v-model="dealerDetails.services.drivelines" :value="1" :unchecked-value="0">
                            {{$t('drivelines-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="electricalSys" v-model="dealerDetails.services.electricalSys" :value="1" :unchecked-value="0">
                            {{$t('electrical-systems')}}
                        </b-form-checkbox>
                        
                    </b-col>
                    <b-col md="4">
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="engineDgnst" v-model="dealerDetails.services.engineDgnst" :value="1" :unchecked-value="0">
                            {{$t('engine-diagnostics-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="fluidExchangeSvc" v-model="dealerDetails.services.fluidExchangeSvc" :value="1" :unchecked-value="0">
                            {{$t('fluid-exchange-services-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="headlamps" v-model="dealerDetails.services.headlamps" :value="1" :unchecked-value="0">
                            {{$t('headlamps-bulbs-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="coolantSys" v-model="dealerDetails.services.coolantSys" :value="1" :unchecked-value="0">
                            {{$t('heating-coolant-systems-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="mufflers" v-model="dealerDetails.services.mufflers" :value="1" :unchecked-value="0">
                            {{$t('mufflers-exhaust-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="oilFilterSvc" v-model="dealerDetails.services.oilFilterSvc" :value="1" :unchecked-value="0">
                            {{$t('oil-lube-filter-services-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4">
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="prvntMntncPkg" v-model="dealerDetails.services.prvntMntncPkg" :value="1" :unchecked-value="0">
                            {{$t('preventative-maintenance-packages-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="chocksStruts" v-model="dealerDetails.services.chocksStruts" :value="1" :unchecked-value="0">
                            {{$t('shocks-struts-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="strtChrgSys" v-model="dealerDetails.services.strtChrgSys" :value="1" :unchecked-value="0">
                            {{$t('starting-charging-systems-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="stateInspections" v-model="dealerDetails.services.stateInspections" :value="1" :unchecked-value="0">
                            {{$t('state-inspections-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="suspSys" v-model="dealerDetails.services.suspSys" :value="1" :unchecked-value="0">
                            {{$t('steering-suspension-systems-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="timingBelts" v-model="dealerDetails.services.timingBelts" :value="1" :unchecked-value="0">
                            {{$t('timing-belts-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="tireInstallation" v-model="dealerDetails.services.tireInstallation" :value="1" :unchecked-value="0">
                            {{$t('tire-installation-label')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <div class="interior-header">{{$t('commercial-tire-services-label')}}</div>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('rubber-products-label')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="tires" v-model="dealerDetails.services.tires" :value="1" :unchecked-value="0">
                            {{$t('tires')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="tubes" v-model="dealerDetails.services.tubes" :value="1" :unchecked-value="0">
                            {{$t('tubes')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="protectors" v-model="dealerDetails.services.protectors" :value="1" :unchecked-value="0">
                            {{$t('protectors')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="retreads" v-model="dealerDetails.services.retreads" :value="1" :unchecked-value="0">
                            {{$t('retreads')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('tire-related-services-label')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="mounting" v-model="dealerDetails.services.mounting" :value="1" :unchecked-value="0">
                            {{$t('mounting')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="dismounting" v-model="dealerDetails.services.dismounting" :value="1" :unchecked-value="0">
                            {{$t('dismounting')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="tireRotation" v-model="dealerDetails.services.tireRotation" :value="1" :unchecked-value="0">
                            {{$t('tire-rotation')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="repairs" v-model="dealerDetails.services.repairs" :value="1" :unchecked-value="0">
                            {{$t('repairs')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('suspension-services-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="springs" v-model="dealerDetails.services.springs" :value="1" :unchecked-value="0">
                            {{$t('springs')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="chockAbsorbers" v-model="dealerDetails.services.chockAbsorbers" :value="1" :unchecked-value="0">
                            {{$t('shock-absorbers-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="terminals" v-model="dealerDetails.services.terminals" :value="1" :unchecked-value="0">
                            {{$t('terminals')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="tieRods" v-model="dealerDetails.services.tieRods" :value="1" :unchecked-value="0">
                            {{$t('tie-rods-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="rubberStops" v-model="dealerDetails.services.rubberStops" :value="1" :unchecked-value="0">
                            {{$t('stops-rubber-stops-label')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('mechanical-services-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="brakes" v-model="dealerDetails.services.brakes" :value="1" :unchecked-value="0">
                            {{$t('brakes')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="axleStraightening" v-model="dealerDetails.services.axleStraightening" :value="1" :unchecked-value="0">
                            {{$t('axle-straightening-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="bearingExchange" v-model="dealerDetails.services.bearingExchange" :value="1" :unchecked-value="0">
                            {{$t('bearing-exchange-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="engineRebuilt" v-model="dealerDetails.services.engineRebuilt" :value="1" :unchecked-value="0">
                            {{$t('engine-rebuilt-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="turbineRebuilt" v-model="dealerDetails.services.turbineRebuilt" :value="1" :unchecked-value="0">
                            {{$t('turbine-rebuilt-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('general-services-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="alignment" v-model="dealerDetails.services.alignment" :value="1" :unchecked-value="0">
                            {{$t('alignment')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="balancing" v-model="dealerDetails.services.balancing" :value="1" :unchecked-value="0">
                            {{$t('balancing')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="perfTracking" v-model="dealerDetails.services.perfTracking" :value="1" :unchecked-value="0">
                            {{$t('tire-performance-tracking-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="bot" v-model="dealerDetails.services.bot" :value="1" :unchecked-value="0">
                            {{$t('bot-check-list')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="vehicleWash" v-model="dealerDetails.services.vehicleWash" :value="1" :unchecked-value="0">
                            {{$t('vehicle-wash-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('engine-items-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="oil" v-model="dealerDetails.services.oil" :value="1" :unchecked-value="0">
                            {{$t('oil')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="fluids" v-model="dealerDetails.services.fluids" :value="1" :unchecked-value="0">
                            {{$t('fluids')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="filters" v-model="dealerDetails.services.filters" :value="1" :unchecked-value="0">
                            {{$t('filters')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="lubricants" v-model="dealerDetails.services.lubricants" :value="1" :unchecked-value="0">
                            {{$t('lubricants')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="belts" v-model="dealerDetails.services.belts" :value="1" :unchecked-value="0">
                            {{$t('belts')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="hoses" v-model="dealerDetails.services.hoses" :value="1" :unchecked-value="0">
                            {{$t('hoses')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('load-products-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="cargoTarp" v-model="dealerDetails.services.cargoTarp" :value="1" :unchecked-value="0">
                            {{$t('cargo-tarp-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="hopes" v-model="dealerDetails.services.hopes" :value="1" :unchecked-value="0">
                            {{$t('hopes')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="lashingStraps" v-model="dealerDetails.services.lashingStraps" :value="1" :unchecked-value="0">
                            {{$t('lashing-straps-label')}}
                        </b-form-checkbox>
                    </b-col>
                    <b-col md="4" class="comm-services-block">
                        <div class="comm-services-header">{{$t('general-products-title')}}</div>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="reflectiveBands" v-model="dealerDetails.services.reflectiveBands" :value="1" :unchecked-value="0">
                            {{$t('reflective-bands-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="commBatteries" v-model="dealerDetails.services.commBatteries" :value="1" :unchecked-value="0">
                            {{$t('batteries-label')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="wheels" v-model="dealerDetails.services.wheels" :value="1" :unchecked-value="0">
                            {{$t('wheels')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="wiperBlades" v-model="dealerDetails.services.wiperBlades" :value="1" :unchecked-value="0">
                            {{$t('wiper-blades')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="jack" v-model="dealerDetails.services.jack" :value="1" :unchecked-value="0">
                            {{$t('jack')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="lamps" v-model="dealerDetails.services.lamps" :value="1" :unchecked-value="0">
                            {{$t('lamps')}}
                        </b-form-checkbox>
                        <b-form-checkbox class="checkbox-center gy-form-checkbox" name="accessories" v-model="dealerDetails.services.accessories" :value="1" :unchecked-value="0">
                            {{$t('accessories')}}
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </template>
        </ExpansionPanel>

        <div class="text-center" style="margin: 15px;">
            <b-button class="gy-btn" v-on:click="toStep3">
                Next Step <b-icon icon="chevron-right"/>
            </b-button>
        </div>
      </div>
    </div>

    <!-- Error modal-->
    <GYModal
      :force-open="showErrorModal"
      header="Error"
      :header-close-button="true"
      @close="onCloseErrorModal"
    >
      <!-- Default content slot -->
      <div class="d-block modal-text">
        {{ errorMessage }}
      </div>
    </GYModal>

    <!-- Loading modal-->
    <GYModal
      :force-open="showLoadingModal"
      :header="$t('loading-title')"
      :hide-footer="true"
    >
      <!-- Default content slot -->
      <div class="d-block modal-text">
        <div class="row">
          <div class="col-md-offset-3 col-md-1 loader-img">
            <img
              src="../assets/images/loading.gif"
              alt="loading"
              style="width: 48px; height: 48px"
            />
          </div>
          <div class="col-md-6 loader-text">
            <p>{{ $t("loading-message") }}</p>
          </div>
        </div>
      </div>
    </GYModal>

  </div>
</template>

<script>
const dealerService = require("../lib/services/dealer.service");
import ProgressBar from "../components/ProgressBar.vue";
import GYModal from "../components/GYModal.vue";
import ExpansionPanel from "../components/ExpansionPanel.vue";
const storeHoursHelper = require("../lib/store-hours");

export default {
    data() {
        return {
            dealerDetails: {
                common: {},
                social: {
                    fbIsNotUsed: false,
                    instIsNotUsed: false,
                    gIsNotUsed: false,
                    twitterIsNotUsed: false,
                    yelpIsNotUsed: false
                },
                closedDates: [],
                location: {
                    initialLat: null,
                    initialLng: null,
                },
                offerings: {},
                services: {},
                storeHours: { ...storeHoursHelper.defaultStoreHoursObject }
            },
            allDealersUpdate: false,

            showLoadingModal: false,
            showErrorModal: false,
            errorMessage: "",

            timeValues: storeHoursHelper.timeValues,
            ampmValues: storeHoursHelper.ampmValues,
            initialClosedDate: null,
        };
    },
    watch: {
        initialClosedDate: function () {
            if (this.initialClosedDate !== null){
                this.dealerDetails.closedDates.push({
                    closedDate: this.initialClosedDate,
                    id: null,
                    isDeleted: 0
                });
                this.initialClosedDate = null;
            }
        },
    },
    computed: {
        undeletedClosedDates: function () {
            return this.dealerDetails.closedDates.filter(item => item.isDeleted === 0);
        }
    },
    methods: {
        toStep3: function () {
            if(this.areAllDaysActive() && this.areAllTimeIntervalsValid()){
                this.$store.dispatch('dealer/setUpdatedDealerData', this.compileDealerDetails(this.dealerDetails));
                this.$router.push('step3');
            } else {
                this.errorMessage = this.$t('invalid-store-hours-message');
                this.showErrorModal = true;
            }

        },
        onCloseErrorModal: function () {
            this.showErrorModal = false;
        },
        updateLatLng(event){
            this.dealerDetails.location.lat = parseFloat(event.latLng.lat());
            this.dealerDetails.location.lng = parseFloat(event.latLng.lng());
        },
        resetLocation(){
            this.dealerDetails.location.lat = this.dealerDetails.location.initialLat;
            this.dealerDetails.location.lng = this.dealerDetails.location.initialLng;
        },
        isIntervalValid(startTime, startAMPM, endTime, endAMPM){
            if(startAMPM === "AM" && endAMPM === "PM"){
                return true;
            }
            if(startAMPM === endAMPM && this.timeValues.indexOf(startTime) < this.timeValues.indexOf(endTime)){
                return true;
            }
            return false;
        },
        addClosedDate(){
            this.dealerDetails.closedDates.push({
                closedDate: (new Date()).toISOString(),
                id: null,
                isDeleted: 0
            });
        },
        areAllTimeIntervalsValid(){
            let timeErrorCount = document.querySelectorAll('.time-error').length;
            if(timeErrorCount > 0)
                return false;
            else return true;
        },
        areAllDaysActive(){
            return this.dealerDetails.storeHours.monIsActive && this.dealerDetails.storeHours.tueIsActive
                    && this.dealerDetails.storeHours.wedIsActive && this.dealerDetails.storeHours.thuIsActive
                    && this.dealerDetails.storeHours.friIsActive && this.dealerDetails.storeHours.satIsActive
                    && this.dealerDetails.storeHours.sunIsActive;
        },
        compileDealerDetails(){
            let result = {
                customerNumber: this.dealerDetails.customerNumber,
                countryKey: this.dealerDetails.countryKey,
                common: { ...this.dealerDetails.common },
                offerings: { ...this.dealerDetails.offerings },
                services: { ...this.dealerDetails.services },
                storeHours: storeHoursHelper.compileStoreHours(this.dealerDetails.storeHours),
                social: {
                    website: this.dealerDetails.social.website,
                    fbUrl: this.dealerDetails.social.fbIsNotUsed ? 'Not used' : this.dealerDetails.social.fbUrl,
                    instUrl: this.dealerDetails.social.instIsNotUsed ? 'Not used' : this.dealerDetails.social.instUrl,
                    gUrl: this.dealerDetails.social.gIsNotUsed ? 'Not used' : this.dealerDetails.social.gUrl,
                    twitterUrl: this.dealerDetails.social.twitterIsNotUsed ? 'Not used' : this.dealerDetails.social.twitterUrl,
                    yelpUrl: this.dealerDetails.social.yelpIsNotUsed ? 'Not used' : this.dealerDetails.social.yelpUrl
                },
                closedDates: [...this.dealerDetails.closedDates],
                location: {
                    lat: this.dealerDetails.location.lat,
                    lng: this.dealerDetails.location.lng,
                    streetAdsress: this.dealerDetails.location.streetAdsress,
                    city: this.dealerDetails.location.city
                }
            }

            return result;
        }
    },
    mounted: async function () {
        this.showLoadingModal = true;

        let response;

        if(!this.$store.state.dealer.selectedDealer){
            return;
        }

        if(this.$store.state.dealer.selectedDealer === 'all'){
            this.$store.dispatch('dealer/setAllDealersUpdate', true);
            this.allDealersUpdate = true;
            response = await dealerService.getDealerDetails(this.$store.getters['auth/getUser'].commonOwner, this.$store.getters['auth/getUser'].countryKey);
            if (response.status === 404) {
                response = await dealerService.getDealerDetails(this.$store.getters['auth/getUser'].customerNumber, this.$store.getters['auth/getUser'].countryKey);
            }
            this.dealerDetails.commonOwner = this.$store.getters['auth/getUser'].commonOwner;
        } else {
            this.$store.dispatch('dealer/setAllDealersUpdate', false);
            response = await dealerService.getDealerDetails(this.$store.state.dealer.selectedDealer, this.$store.getters['auth/getUser'].countryKey);
        }

        this.showLoadingModal = false;

        if (response.status === 200) {
            this.dealerDetails = { ...this.dealerDetails, ...response.data.dealerDetails};

            this.dealerDetails.storeHours = storeHoursHelper.parseStoreHours(response.data.dealerDetails.storeHours);

            this.dealerDetails.location.initialLat = this.dealerDetails.location.lat;
            this.dealerDetails.location.initialLng = this.dealerDetails.location.lng;

            this.dealerDetails.social.fbUrl === 'Not used' ? this.$set(this.dealerDetails.social, 'fbIsNotUsed', true) : this.$set(this.dealerDetails.social, 'fbIsNotUsed', false);
            this.dealerDetails.social.instUrl === 'Not used' ? this.$set(this.dealerDetails.social, 'instIsNotUsed', true) : this.$set(this.dealerDetails.social, 'instIsNotUsed', false);
            this.dealerDetails.social.gUrl === 'Not used' ? this.$set(this.dealerDetails.social, 'gIsNotUsed', true) : this.$set(this.dealerDetails.social, 'gIsNotUsed', false);
            this.dealerDetails.social.twitterUrl === 'Not used' ? this.$set(this.dealerDetails.social, 'twitterIsNotUsed', true) : this.$set(this.dealerDetails.social, 'twitterIsNotUsed', false);
            this.dealerDetails.social.yelpUrl === 'Not used' ? this.$set(this.dealerDetails.social, 'yelpIsNotUsed', true) : this.$set(this.dealerDetails.social, 'yelpIsNotUsed', false);

            this.dealerDetails.social.fbUrl = this.dealerDetails.social.fbIsNotUsed ? '' : this.dealerDetails.social.fbUrl;
            this.dealerDetails.social.instUrl = this.dealerDetails.social.instIsNotUsed ? '' : this.dealerDetails.social.instUrl;
            this.dealerDetails.social.gUrl = this.dealerDetails.social.gIsNotUsed ? '' : this.dealerDetails.social.gUrl;
            this.dealerDetails.social.twitterUrl = this.dealerDetails.social.twitterIsNotUsed ? '' : this.dealerDetails.social.twitterUrl;
            this.dealerDetails.social.yelpUrl = this.dealerDetails.social.yelpIsNotUsed ? '' : this.dealerDetails.social.yelpUrl;
        } else {
            this.errorMessage = response.data.message;
            this.showErrorModal = true;
        }
    },
    components: {
        ProgressBar,
        GYModal,
        ExpansionPanel,
    },
    beforeRouteEnter(to, from, next) {
        // Enter reset-password route only if email prop is not empty
        next(vm => {
            if(!vm.$store.state.dealer.selectedDealer){
                vm.$router.push({path: '/step1'});
            }
        })
    },
};
</script>

<style scoped>
.details-container{
    padding: 0px 60px;
}

@media (max-width: 768px) {
    .details-container {
        padding-right: 10px;
        padding-left: 10px;
    }
    .not-used {
        margin-top: 5px;
        margin-bottom: 10px;
    }
    .gy-form-group{
        padding-bottom: 15px;
    }
}

.store-name-phone-container{
    padding-bottom: 20px;
}

.gy-form-group{
    padding-bottom: 15px;
}

.gy-form-checkbox{
    padding-bottom: 5px;
}

</style>