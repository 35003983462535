
const state = {
    selectedDealer: JSON.parse(localStorage.getItem('selectedDealer')) || null,
    updatedDealerData: JSON.parse(localStorage.getItem('updatedDealerData')) || null,
    allDealersUpdate: JSON.parse(localStorage.getItem('allDealersUpdate')) || false,
    afterSuccessfulUpdate: false,
}

// Actions 
const actions = {
    setSelectedDealer({ commit }, selectedDealer) {
        localStorage.setItem('selectedDealer', JSON.stringify(selectedDealer));
        commit('SET_SELECTED_DEALER', selectedDealer);
    },
    setUpdatedDealerData({ commit }, updatedDealerData) {
        localStorage.setItem('updatedDealerData', JSON.stringify(updatedDealerData));
        commit('SET_UPDATED_DEALER_DATA', updatedDealerData);
    },
    setAllDealersUpdate({ commit }, allDealersUpdate) {
        localStorage.setItem('allDealersUpdate', allDealersUpdate);
        commit('SET_ALL_DEALERS_UPDATE', allDealersUpdate);
    },
    setAfterSuccessfulUpdate({ commit }, value) {
        commit('SET_AFTER_SUCCESSFUL_UPDATE', value);
    },
    resetDealerData({ commit }){
        localStorage.removeItem('selectedDealer');
        commit('SET_SELECTED_DEALER', null);

        localStorage.removeItem('updatedDealerData');
        commit('SET_UPDATED_DEALER_DATA', null);

        localStorage.removeItem('allDealersUpdate');
        commit('SET_ALL_DEALERS_UPDATE', false);

        commit('SET_AFTER_SUCCESSFUL_UPDATE', false);
    }
}

// Mutations
const mutations = {
    SET_SELECTED_DEALER(state, data) {
        state.selectedDealer = data;
    },
    SET_UPDATED_DEALER_DATA(state, data) {
        state.updatedDealerData = data;
    },
    SET_ALL_DEALERS_UPDATE(state, data) {
        state.allDealersUpdate = data;
    },
    SET_AFTER_SUCCESSFUL_UPDATE(state, data) {
        state.afterSuccessfulUpdate = data;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}