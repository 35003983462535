<template>
  <div class="nav-container sticky-top">
    <b-navbar toggleable="lg" class="custom-gy" >
      
      <b-navbar-brand to="/">
        <img src="../assets/images/goodyear_logo.png" class="d-inline-block align-top gy-logo" alt="Goodyear">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto align-items-left">
            <b-nav-form>
                <span class="navbar-lang-text">{{$t('select-lang-title')}}:</span>
                <LangSelect/>
            </b-nav-form>
            <b-nav-item to="/logout" v-if="isUserLoggedIn">{{ $t("logout") }}<b-icon-chevron-right></b-icon-chevron-right></b-nav-item>
          </b-navbar-nav>
      </b-collapse>
      
    </b-navbar>
  </div>
</template>

<script>
import LangSelect from "./LangSelect";

export default {
  data() {
    return {
      selected: null,
    }
  },
  computed: {
    isUserLoggedIn () {
      return this.$store.state.auth.status.isLoggedIn
    }
  },
  components: {
    LangSelect,
  }
};

</script>

<style scoped>
    .navbar.custom-gy { 
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 25px;
    }

    .navbar a {
        color: #fff;
    }

    .navbar-light .navbar-nav .nav-link:active, .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .show > .nav-link {
        color: #fff;
        text-decoration: underline;
    }

    .navbar.custom-gy a.nav-link {
        color: #fff;
        padding: 9px 10px 0 20px;
    }

    .navbar.custom-gy a:hover, .navbar.custom-gy a:focus, .navbar.custom-gy a:active {
        color: #fff;
        text-decoration: underline;
    }
    
    .nav-container {
      background-color: #274ba7;
      background-repeat: repeat;
      border-bottom: 2px solid #fd0;
    }

    .navbar.custom-gy .gy-logo {
      height: 40px;
    }

    .navbar.custom-gy .navbar-brand {
      padding: 0px;
    }

    li.navbar-text {
        color: #fff;
    }

    .navbar-lang-text {
        padding: 8px 10px 8px 0px;
        color: #fff;
    }

</style>
