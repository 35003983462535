<template>
  <div>
    <progress-bar :step="4"></progress-bar>

    <b-row>
      <b-col md="12" class="text-center">

        <h1 class="green">
          <b-icon icon="check-circle"/> {{$t('success')}}
        </h1>

        <div v-if="!allDealersUpdate" class="cust-number">{{$t('profile-for-customer')}} {{dealerDetails.customerNumber}} {{$t('has-been-updated')}}</div>
        <div v-if="allDealersUpdate" class="cust-number">{{$t('profile-for-common')}} {{dealerDetails.commonOwner}} {{$t('has-been-updated')}}</div>

        <br>

        <b-row align-h="center">
            <b-col md="3" cols="5">
                <b-button class="gy-btn" v-on:click="toStep1" >{{$t('start-over-label')}}</b-button>
            </b-col>

            <b-col md="3" cols="5">
                <b-button class="gy-btn" to="logout" >{{$t('logout')}}</b-button>
            </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ProgressBar from "../components/ProgressBar.vue";

export default {
    data() {
        return {
            allDealersUpdate: false,
            dealerDetails: {
                customerNumber: "",
                commonOwner: ""
            }
        }
    },
    mounted: function (){

        if(!this.$store.state.dealer.afterSuccessfulUpdate){
            return;
        }

        this.dealerDetails = { ...this.dealerDetails, ...this.$store.state.dealer.updatedDealerData };
        this.allDealersUpdate = this.$store.state.dealer.allDealersUpdate;

        if(this.allDealersUpdate) {
            this.dealerDetails.commonOwner = this.$store.getters['auth/getUser'].commonOwner;
        }
    },
    methods: {
        toStep1: function () {
            this.$store.dispatch('dealer/setAfterSuccessfulUpdate', false);
            
            this.$router.push('step1');
        },
    },
    components: {
        ProgressBar,
    },
    beforeRouteEnter(to, from, next) {
        // Enter reset-password route only if email prop is not empty
        next(vm => {
            if(!vm.$store.state.dealer.afterSuccessfulUpdate){
                vm.$router.push({path: '/step1'});
            }
        })
    },
};

</script>

<style scoped>
    
</style>