const state = {
  selectedLang: localStorage.getItem('ladealerprofileLanguage') ||  "en",
}

// Actions 
const actions = {
  setSelectedLang({ commit }, selectedLang) {
    localStorage.setItem('ladealerprofileLanguage', selectedLang);
    commit('SET_SELECTED_LANG', selectedLang);
  },
}

// Mutations
const mutations = {
  SET_SELECTED_LANG(state, data) {
      state.selectedLang = data;
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}