<template>
  <div>
    <div class="row page-title">
      <h1 class="text-center">{{ $t("app-title") }}</h1>
    </div>

    <div v-if="accountHasBeenCreated" class="text-center account-success-container">
        <div class="green success-text">
            <b-icon icon="check-circle"></b-icon> {{$t('account-created')}}
        </div>
        <p>{{$t('account-created-hint')}}.</p>
    </div>

    <div class="row justify-content-md-center">
      <div class="col-md-5 login-container">
        <b-card
          header-tag="header"
          header-class="gy-card-header"
          footer-tag="footer"
        >
          <template #header>
            {{ $t("log-in") }}
          </template>
          <b-form @submit.prevent="login">
            <b-form-group
              id="input-group-email-address"
              :label="$t('email-address')"
              label-for="email"
            >
              <b-form-input
                id="email-address"
                type="email"
                v-model="email"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-password"
              :label="$t('password')"
              label-for="password"
            >
              <b-form-input
                id="password"
                type="password"
                v-model="password"
                required
              ></b-form-input>
              <a class="small" type="button" v-on:click="openResetPasswordModal">{{$t('forgot-password-label')}}?</a>
            </b-form-group>

            <div v-if="wrongCredentials">
              <span role="alert" class="danger">{{$t('wrong-credentials')}}.</span>
            </div>
            <div v-if="userDoesntExist">
              <span role="alert" class="danger">{{$t('account-not-belong-to-account')}}.</span>
            </div>

            <b-button class="gy-btn" type="submit">
                <div class="spinner-border spinner-border-sm" role="status" v-if="showLoadingSpinner">
                    <span class="sr-only">Loading...</span>
                </div>
                {{ $t("log-in") }}
            </b-button>
          </b-form>
        </b-card>
      </div>
      <div class="col-md-5 text-center">
        <h2>{{ $t("dont-have-account") }}?</h2>
        <b-button class="gy-btn" to="/sign-up">{{ $t("sign-up-here") }}</b-button>
      </div>
    </div>

    <!-- Reset password modal-->
    <GYModal
        :force-open="showResetPasswordModal"
        :header="$t('password-reset')"
        :header-close-button="true"
        @close="onCloseResetPasswordModal"
        
    >
        <!-- Default content slot -->
        <div class="d-block modal-text">
            <p>{{$t('enter-email-to-reset-password')}}</p>
            <b-form-input v-model="emailResetPassword" placeholder=""></b-form-input>
            <div v-if="wrongResetPwEmail">
                <span role="alert" class="danger">{{$t('account-not-belong-to-account')}}.</span>
            </div>
        </div>

        <template v-slot:footer>
            <div class="text-center">
                <b-button class="gy-btn btn-option" v-on:click="forgotPassword">
                    <div class="spinner-border spinner-border-sm" role="status" v-if="showLoadingSpinner">
                        <span class="sr-only">Loading...</span>
                    </div>
                    {{$t('reset-password')}}
                </b-button>
            </div>
        </template>
    </GYModal>

    <!-- Error modal-->
    <GYModal
        :force-open="showErrorModal"
        header="Error"
        :header-close-button="true"
        @close="onCloseErrorModal"
        
    >
        <!-- Default content slot -->
        <div class="d-block modal-text">
            {{errorMessage}}
        </div>
    </GYModal>

  </div>
</template>

<script>
const authService = require("../lib/services/auth.service");
const userService = require("../lib/services/user.service");
import GYModal from '../components/GYModal.vue';

export default {
    props: ['accountHasBeenCreated'],
    data() {
        return {
            email: "",
            password: "",
            wrongCredentials: false,
            userDoesntExist: false,
            showLoadingSpinner: false,
            emailResetPassword: "",
            showResetPasswordModal: false,
            wrongResetPwEmail: false,
            showErrorModal: false,
            errorMessage: "",
        };
    },
    watch: {
        email: function () {
            if (this.wrongCredentials || this.userDoesntExist){
                this.userDoesntExist = false;
                this.wrongCredentials = false;
            }
        },
        password: function () {
            if (this.wrongCredentials)
                this.wrongCredentials = false;
        },
    },
    computed: {},

    methods: {
        login: async function () {
            try {
                this.wrongCredentials = false;
                this.userDoesntExist = false;

                this.showLoadingSpinner = true;
                let response = await authService.login(this.email, this.password);
                this.showLoadingSpinner = false;

                switch (response.status) {
                    case 200:
                        this.$store.dispatch('auth/login', response.data.user);

                        if(response.data.user.isTempPassword){
                            this.$router.push({ name: 'reset-password' });
                        } else {
                            this.$router.push({ name: 'step1' });
                        }

                        break;
                    case 401:
                        this.wrongCredentials = true;
                        break;
                    case 404:
                        this.userDoesntExist = true;
                        break;
                    case 500:
                        this.errorMessage = response.data.message;
                        this.showErrorModal = true;
                        break;
                }

            } catch (error) {
                console.error(error);
            }
        },
        openResetPasswordModal: function () {
            this.showResetPasswordModal = true;
        },
        onCloseResetPasswordModal: function () {
            this.showResetPasswordModal = false;
        },
        onCloseErrorModal: function () {
            this.showErrorModal = false;
        },
        forgotPassword: async function () {
            this.wrongResetPwEmail = false;

            try {
                this.showLoadingSpinner = true;
                let response = await userService.forgotPassword(this.emailResetPassword);
                this.showLoadingSpinner = false;

                switch (response.status) {
                    case 200:
                        this.showResetPasswordModal = false;
                        this.$router.push({ name: 'reset-password', params: { email: this.emailResetPassword } })
                        break;
                    case 404:
                        this.wrongResetPwEmail = true;
                        break;
                    case 500:
                        this.errorMessage = response.data.message;
                        this.showResetPasswordModal = false;
                        this.showErrorModal = true;
                        break;
                }
            } catch (error) {
                console.error(error);
            }
        },
    },
    components: {
        GYModal,
    }
};
</script>

<style lang="scss" scoped>
td.text-center {
  width: 73px;
}

.account-success-container .success-text {
  font-size: 28px;
}
.comm-services-block {
  margin: 20px 0;
}
.comm-services-header {
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li {
    padding-left: 0;
    margin-left: 40px;
  }
  .login-container {
    border-right: 1px solid #dbdfe4;
    padding-right: 50px;
  }
  .account-success-container {
    margin-bottom: 50px;
  }
}

td.text-center {
  width: 73px;
}

label.closed {
  border-left: 1px solid lightgray;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 20px;
}

.gy-card-header {
  background-color: #274ba7;
  color: #ffffff;
  text-transform: uppercase;
  padding: 5px 0 5px 15px;
  font-family: "BarlowCondensed-SemiBold", sans-serif !important;
}

.login-title {
  padding: 10px 0px 10px 0px;
}
</style>