<template>
  <div class="login-admin-container">
    <div v-if="isLoading" class="row">
      <div class="col-md-offset-3 col-md-1 loader-img">
        <img
          src="../assets/images/loading.gif"
          alt="loading"
          style="width: 48px; height: 48px"
        />
      </div>
      <div class="col-md-6 loader-text">
        <p>Loading...</p>
      </div>
    </div>
    <div v-if="!isLoading && error" class="row">
        <div class="col-12">
            <p><span role="alert" class="danger">Error: {{errorMessage}}</span></p>
        </div>
        <div class="col-12">
            <b-button class="gy-btn" to="/login">Go to login page</b-button>
        </div>
    </div>
  </div>
</template>

<script>
const authService = require("../lib/services/auth.service");

export default {
  data() {
    return {
      isLoading: true,

      error: false,
      errorMessage: "",
    };
  },
  mounted: async function () {
    let response = await authService.loginAdmin(this.$route.query.a_id);

    this.isLoading = false;

    if (response.status === 200) {
      this.$store.dispatch("auth/loginAdmin", response.data.admin);
      this.$router.push("step1");
    } else {
      this.errorMessage = response.data.message;
      this.error = true;
    }
  },
};
</script>

<style scoped>

.login-admin-container {
    padding: 10px;
}

</style>