const axios =  require('axios');

/**
 * Forgot password (set temp password to user)
 * 
 * @param {string} email Email
 */
 async function forgotPassword(email) {
    try{
        return await axios.post(`/api/forgot-password`, JSON.stringify({ email }));
    } catch (error) {
        return error.response;
    }
}

/**
 * Verify user/dealer (send verification code)
 * 
 * @param {string} email
 * @param {string} customerNumber
 * @param {string} postalCode
 */
 async function verify(email, customerNumber, postalCode) {

    try{
        return await axios.post(`/api/verify`, JSON.stringify({ email, customerNumber, postalCode }));
    } catch (error) {
        return error.response;
    }
}

/**
 * Register new user
 * 
 * @param {string} email 
 * @param {string} customerNumber 
 * @param {string} postalCode 
 * @param {string} verificationCode 
 * @param {string} firstName 
 * @param {string} lastName 
 * @param {string} password 
 * @returns 
 */
 async function register(email, customerNumber, postalCode, verificationCode, firstName, lastName, password) {

    try{
        return await axios.post(`/api/register`, JSON.stringify({ email, customerNumber, postalCode, verificationCode, firstName, lastName, password}));
    } catch (error) {
        return error.response;
    }
}

/**
 * Reset user password
 * 
 * @param {string} email
 * @param {string} password 
 * @param {string} newPassword 
 * @returns 
 */
 async function resetPassword(email, password, newPassword) {

    try{
        return await axios.post(`/api/reset-password`, JSON.stringify({ email, password, newPassword}));
    } catch (error) {
        return error.response;
    }
}


module.exports.forgotPassword = forgotPassword;
module.exports.verify = verify;
module.exports.register = register;
module.exports.resetPassword = resetPassword;