<template>
  <div>
    <div class="row page-title">
      <h1 class="text-center">{{$t('create-your-la-account')}}</h1>
    </div>
    <div class="row justify-content-md-center">
      <div class="col-md-4 col-md-offset-2 pwd-rules-container d-none d-lg-block">
        <h2>{{$t('password')}}</h2>
        <ul>
            <li>{{$t('char-range-rule')}}</li>
            <li>{{$t('spaces-rule')}}</li>
            <li>{{$t('spec-char-rule')}}</li>
            <li>{{$t('consecutive-char-rule')}}</li>
            <li>{{$t('begin-number-rule')}}</li>
            <li>{{$t('email-rule')}}</li>
            <li>{{$t('full-name-rule')}}</li>
            <li>{{$t('password-rule')}}</li>
            <li>{{$t('last-five-passwords-rule')}}</li>
            <li>{{$t('num-digit-rule')}}</li>
        </ul>
      </div>
      <div class="col-md-4">
        <div class="text-right">
            <span class="small">
            {{$t('already-have-account')}}
            <router-link to="/login">{{$t('sign-in')}}</router-link>
            </span>
        </div>
        <div v-show="!isStep2">
            <b-card
                header-tag="header"
                header-class="gy-card-header"
                footer-tag="footer"
            >
                <template #header>
                    {{$t('register-step-one')}}
                </template>

                <p>{{$t("enter-info-below")}}</p>

                <b-form @submit.prevent="verify">
                    <b-form-group
                        id="input-group-email-address"
                        label-for="email-address"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('email-address')}}
                        </template>
                        <b-form-input
                            id="email-address"
                            type="email"
                            v-model="$v.verifyForm.email.$model"
                        ></b-form-input>
                        <span v-if="$v.verifyForm.email.$error && !$v.verifyForm.email.required" role="alert" class="danger">{{$t('email-required-message')}}</span>
                    </b-form-group>

                    <b-form-group
                        id="input-group-customer-number"
                        label-for="customer-number"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('customer-number')}}
                        </template>
                        <b-form-input
                            id="customer-number"
                            type="text"
                            v-model="$v.verifyForm.customerNumber.$model"
                            v-on:change="verifyFailed = false"
                        ></b-form-input>
                        <span v-if="$v.verifyForm.customerNumber.$error && !$v.verifyForm.customerNumber.required" role="alert" class="danger">{{$t('customer-required-message')}}</span>
                    </b-form-group>

                    <b-form-group
                        id="input-group-postal-code"
                        :description="$t('postal-associate-customer')"
                        label-for="postal-code"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('postal-code')}}
                        </template>
                        <b-form-input
                            id="postal-code"
                            type="text"
                            v-model="$v.verifyForm.postalCode.$model"
                            v-on:change="verifyFailed = false"
                        ></b-form-input>
                        <span v-if="$v.verifyForm.postalCode.$error && !$v.verifyForm.postalCode.required" role="alert" class="danger">{{$t('postal-code-required-message')}}</span>
                    </b-form-group>

                    <div v-if="verifyFailed">
                        <span role="alert" class="danger">{{$t('postal-customer-not-match')}}</span>
                    </div>

                    <b-button class="gy-btn" type="submit" :disabled="$v.verifyForm.$invalid">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="showLoadingSpinner">
                            <span class="sr-only">Loading...</span>
                        </div>
                        {{ $t("send-verification-code") }}
                    </b-button>
                </b-form>
            </b-card>
        </div>
        <div v-show="isStep2">
            <b-card
                header-tag="header"
                header-class="gy-card-header"
                footer-tag="footer"
            >
                <template #header>
                    {{$t('register-step-two')}}
                </template>

                <div class="alert alert-success" role="alert">
                    <b-icon icon="check-circle-fill" variant="success"></b-icon>
                    <span v-if="!this.emailWasResent"> {{$t('verification-code-sent-to')}} {{this.verifyForm.email}}</span>
                    <span v-if="this.emailWasResent"> {{$t('verification-code-resent-to')}} {{this.verifyForm.email}}</span>
                    <br>
                    <a type="button" v-on:click="verify(); emailWasResent = true">{{$t('resend-activation-code')}}</a>
                </div>

                <b-form @submit.prevent="signup">
                    <b-form-group
                        id="input-group-verification-code"
                        label-for="verification-code"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('verification-code')}}
                        </template>

                        <b-form-input
                            id="verification-code"
                            type="text"
                            v-model="$v.signupForm.verificationCode.$model"
                            v-on:change="invalidVerificationCode = false"
                        ></b-form-input>
                        <span v-if="$v.signupForm.verificationCode.$error && !$v.signupForm.verificationCode.required" role="alert" class="danger">{{$t('enter-verification-code')}}</span>
                        <div v-if="invalidVerificationCode">
                            <span role="alert" class="danger">{{$t('incorrect-verification-code')}}</span>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-name"
                        label-for="name"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('name')}}
                        </template>

                        <b-form-row>
                            <b-col>
                                <b-form-input
                                    id="first-name"
                                    type="text"
                                    v-model="$v.signupForm.firstName.$model"
                                ></b-form-input>
                                <span v-if="$v.signupForm.firstName.$error && !$v.signupForm.firstName.required" role="alert" class="danger">{{$t('first-name-required')}}</span>
                            </b-col>
                            <b-col>
                                <b-form-input
                                    id="last-name"
                                    type="text"
                                    v-model="$v.signupForm.lastName.$model"
                                ></b-form-input>
                                <span v-if="$v.signupForm.lastName.$error && !$v.signupForm.lastName.required" role="alert" class="danger">{{$t('last-name-required')}}</span>
                            </b-col>
                        </b-form-row>
                    </b-form-group>

                    <b-form-group
                        id="input-group-password"
                        label-for="password"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('create-password-message')}}
                        </template>
                        
                        <b-form-input
                            id="password"
                            type="password"
                            v-model="$v.signupForm.password.$model"
                            v-on:change="passwordRulesError = false"
                        ></b-form-input>
                        <span v-if="$v.signupForm.password.$error && !$v.signupForm.password.required" role="alert" class="danger">{{$t('please-create-password')}}</span>
                        <div v-if="passwordRulesError">
                            <span role="alert" class="danger">{{$t('password-not-match-rules')}}</span>
                        </div>
                    </b-form-group>

                    <b-form-group
                        id="input-group-confirm-password"
                        label-for="confirm-password"
                    >
                        <template #label>
                            <span style="color:red">*</span>{{$t('confirm-password-message')}}
                        </template>
                        
                        <b-form-input
                            id="confirm-password"
                            type="password"
                            v-model="$v.signupForm.confirmPassword.$model"
                        ></b-form-input>
                        <span v-if="$v.signupForm.confirmPassword.$error && !$v.signupForm.confirmPassword.sameAsPassword" role="alert" class="danger">{{$t('password-unconfirmed')}}</span>
                    </b-form-group>

                    <b-button class="gy-btn" type="submit" :disabled="$v.signupForm.$invalid">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="showLoadingSpinner">
                            <span class="sr-only">Loading...</span>
                        </div>
                        {{$t("sign-up")}}
                    </b-button>
                </b-form>
            </b-card>
        </div>
      </div>
    </div>

    <!-- Error modal-->
    <GYModal
        :force-open="showErrorModal"
        header="Error"
        :header-close-button="true"
        @close="onCloseErrorModal"
        
    >
        <!-- Default content slot -->
        <div class="d-block modal-text">
            {{errorMessage}}
        </div>
    </GYModal>

    <!-- Loading modal-->
    <GYModal 
        :force-open="showLoadingModal"
        :header="$t('loading-title')"
        :hide-footer="true"
    >
        <!-- Default content slot -->
        <div class="d-block modal-text">
            <div class="row">
                <div class="col-md-offset-3 col-md-1 loader-img">
                    <img src="../assets/images/loading.gif" alt="loading" style="width:48px;height:48px;">
                </div>
                <div class="col-md-6 loader-text">
                    <p>{{$t('loading-message')}}</p>
                </div>
            </div>
        </div>
    </GYModal>

  </div>
</template>

<script>
const userService = require("../lib/services/user.service");
import GYModal from '../components/GYModal.vue';
const { required, sameAs } = require('vuelidate/lib/validators');
const constants = require('../constants');

export default {
    data() {
        return {
            verifyForm: {
                email: "",
                customerNumber: "",
                postalCode: ""
            },

            signupForm: {
                verificationCode: "",
                firstName: "",
                lastName: "",
                password: "",
                confirmPassword: ""
            },

            verifyFailed: false,
            emailWasResent: false,
            invalidVerificationCode: false,
            passwordRulesError: false,

            showLoadingModal: false,
            showLoadingSpinner: false,
            showErrorModal: false,
            errorMessage: "",

            isStep2: false,
        };
    },
    validations: {
        verifyForm: {
            email: {
                required
            },
            customerNumber: {
                required
            },
            postalCode: {
                required
            }
        },

        signupForm: {
            verificationCode: {
                required
            },
            firstName: {
                required
            },
            lastName: {
                required
            },
            password: {
                required
            },
            confirmPassword: {
                sameAsPassword: sameAs('password')
            }
        },
    },
    methods: {
        verify: async function () {
            this.$v.verifyForm.$touch();

            if (!this.$v.verifyForm.$invalid) {
                this.showLoadingSpinner = true;
                let response = await userService.verify(this.verifyForm.email, this.verifyForm.customerNumber, this.verifyForm.postalCode);
                this.showLoadingSpinner = false;

                switch (response.status) {
                    case 200:
                        this.isStep2 = true;
                        break;
                    case 404:
                        this.verifyFailed = true;
                        break;
                    case 400:
                    case 500:
                        this.errorMessage = response.data.message;
                        this.showErrorModal = true;
                        break;
                }
            }
        },
        signup: async function () {
            this.$v.verifyForm.$touch();

            if (!this.$v.verifyForm.$invalid) {
                this.invalidVerificationCode = false;
                this.passwordRulesError = false;

                this.showLoadingSpinner = true;
                let response = await userService.register(this.verifyForm.email, this.verifyForm.customerNumber, this.verifyForm.postalCode, this.signupForm.verificationCode, 
                                                            this.signupForm.firstName, this.signupForm.lastName, this.signupForm.password);
                this.showLoadingSpinner = false;
                
                switch (response.status) {
                    case 200:
                        this.$router.push({ name: 'login', params: { accountHasBeenCreated: true } })
                        break;
                    case 400:
                        switch (response.data.type) {
                            case constants.REQUIRED_PARAMS_ERROR_TYPE:
                                this.errorMessage = response.data.message;
                                this.showErrorModal = true;
                                break;
                            case constants.VERIFICATION_CODE_ERROR_TYPE:
                                this.invalidVerificationCode = true;
                                break;
                            case constants.PASSWORD_RULES_ERROR_TYPE:
                                this.passwordRulesError = true;  
                        }
                        break;
                    case 404:
                    case 500:
                        this.errorMessage = response.data.message;
                        this.showErrorModal = true;
                        break;
                }
            }
        },
        openResetPasswordModal: function () {
            this.showResetPasswordModal = true;
        },
        onCloseResetPasswordModal: function () {
            this.showResetPasswordModal = false;
        },
        onCloseErrorModal: function () {
            this.showErrorModal = false;
        },
    },
    components: {
        GYModal,
    }
};
</script>

<style lang="scss" scoped>

td.text-center {
  width: 73px;
}

.account-success-container .success-text {
  font-size: 28px;
}
.comm-services-block {
  margin: 20px 0;
}
.comm-services-header {
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li {
    padding-left: 0;
    margin-left: 40px;
  }
  .pwd-rules-container {
    padding-right: 50px;
    padding-top: 60px;
  }
  .account-success-container {
    margin-bottom: 50px;
  }
}

td.text-center {
  width: 73px;
}

label.closed {
  border-left: 1px solid lightgray;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 20px;
}

</style>