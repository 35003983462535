const axios = require('axios');

/**
 * Get dealers list
 */
async function getDealersList() {

    try {
        return await axios.get(`/api/dealers`);
    } catch (error) {
        return error.response;
    }
}

/**
 * Get dealer details
 * 
 * @param {string} customerNumber 
 * @param {string} countryKey 
 * @returns 
 */
async function getDealerDetails(customerNumber, countryKey) {

    try {
        return await axios.get(`/api/dealer-details`, { params: { customerNumber, countryKey } });
    } catch (error) {
        return error.response;
    }
}

/**
 * Update dealer details
 * 
 * @param {string} customerNumber 
 * @param {string} countryKey 
 * @param {} dealerDetails 
 * @returns 
 */
async function updateDealerDetails(customerNumber, countryKey, dealerDetails) {

    try {
        return await axios.post(`/api/update-dealer-details`, JSON.stringify({ customerNumber: customerNumber, countryKey: countryKey, data: dealerDetails }));
    } catch (error) {
        return error.response;
    }
}

/**
 * Update ALL dealer details
 * 
 * @param {} dealerDetails 
 * @returns 
 */
 async function updateAllDealersDetails(dealerDetails) {

    try {
        return await axios.post(`/api/update-all-dealers-details`, JSON.stringify({ data: dealerDetails }));
    } catch (error) {
        return error.response;
    }
}

module.exports.getDealersList = getDealersList;
module.exports.getDealerDetails = getDealerDetails;
module.exports.updateDealerDetails = updateDealerDetails;
module.exports.updateAllDealersDetails = updateAllDealersDetails;

