<template>
  <div>
    <progress-bar :step="1"></progress-bar>

    <div class="row">
      <div class="col-md-12">
        <h1>{{ $t("select-location-title") }}</h1>

        <b-table
            :items="dealers"
            :fields="fields"
            select-mode="single"
            responsive="sm"
            ref="dealersTable"
            :busy="isBusy"
            hover
            @row-clicked="item => selectDealer(item)"
        >

            <template slot="top-row" >
                <td v-on:click="selectedDealer='all'"><b-form-radio class="radio-center" v-model="selectedDealer" :value="'all'" name="selectDealer" ></b-form-radio></td>
                <td v-on:click="selectedDealer='all'">
                    <span class="font-weight-bold">{{$t('select-all-locations-title')}}</span>
                </td>
                <td v-on:click="selectedDealer='all'"></td>
                <td v-on:click="selectedDealer='all'"></td>
                <td v-on:click="selectedDealer='all'"></td>
                <td v-on:click="selectedDealer='all'"></td>
                
            </template>

            <template #head(selected)><span></span></template>
            <template #head(customerNumber)><span class="font-weight-normal">{{$t('customer')}} #</span></template>
            <template #head(commercialName)><span class="font-weight-normal">{{$t('store-name')}}</span></template>
            <template #head(streetAddress)><span class="font-weight-normal">{{$t('street-address')}}</span></template>
            <template #head(city)><span class="font-weight-normal">{{$t('city')}}</span></template>
            <template #head(district)><span class="font-weight-normal">{{$t('district')}}</span></template>

            <!-- Example scoped slot for select state illustrative purposes -->
            <template #cell(selected)="data">
                <b-form-radio class="radio-center" v-model="selectedDealer" :value="data.item.customerNumber" name="selectDealer" ></b-form-radio>
            </template>

            <template v-slot:table-busy>
                <div class="text-center my-2 table-loading" >
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>&nbsp;Loading...</strong>
                </div>
            </template>
        </b-table>

        <div class="pull-left">
            <b-button class="gy-btn" v-on:click="toStep2" :disabled="!selectedDealer">
                {{ $t("edit") }} <b-icon icon="chevron-right"/>
            </b-button>
        </div>
      </div>
    </div>

    <!-- Error modal-->
    <GYModal
      :force-open="showErrorModal"
      header="Error"
      :header-close-button="true"
      @close="onCloseErrorModal"
    >
      <!-- Default content slot -->
      <div class="d-block modal-text">
        {{ errorMessage }}
      </div>
    </GYModal>

    <!-- Loading modal-->
    <GYModal
      :force-open="showLoadingModal"
      :header="$t('loading-title')"
      :hide-footer="true"
    >
      <!-- Default content slot -->
      <div class="d-block modal-text">
        <div class="row">
          <div class="col-md-offset-3 col-md-1 loader-img">
            <img
              src="../assets/images/loading.gif"
              alt="loading"
              style="width: 48px; height: 48px"
            />
          </div>
          <div class="col-md-6 loader-text">
            <p>{{ $t("loading-message") }}</p>
          </div>
        </div>
      </div>
    </GYModal>
  </div>
</template>

<script>
const dealerService = require("../lib/services/dealer.service");
import ProgressBar from "../components/ProgressBar.vue";
import GYModal from "../components/GYModal.vue";

export default {
    data() {
        return {
            dealers: [],
            selectedDealer: null,
            allDealersSelected: false,

            fields: [
                "selected",
                "customerNumber",
                "commercialName",
                "streetAddress",
                "city",
                "district",
            ],

            isBusy: false,

            showLoadingModal: false,
            showErrorModal: false,
            errorMessage: "",
        };
    },
    computed: {},
    methods: {
        toStep2: function () {
            this.$store.dispatch('dealer/setSelectedDealer', this.selectedDealer);
            
            this.$router.push('step2');
        },
        selectDealer(item) {
            this.selectedDealer = item.customerNumber;
        },
        onCloseErrorModal: function () {
            this.showErrorModal = false;
        },
    },
    mounted: async function () {
        this.isBusy = true;
        let response = await dealerService.getDealersList();
        if (response.status === 200) {
            this.dealers = response.data.dealers;
            this.isBusy = false;
        } else {
            this.errorMessage = response.data.message;
            this.showErrorModal = true;
            this.isBusy = false;
        }
    },
    components: {
        ProgressBar,
        GYModal,
    },
};
</script>

<style scoped>
</style>