const axios = require('axios');

/**
 * Login user
 * 
 * @param {string} email Email
 * @param {string} password Password
 */
async function login(email, password) {
    try {
        return await axios.post(`/api/login`, JSON.stringify({ email: email, password: password }));
    } catch (error) {
        return error.response;
    }
}

/**
 * Refresh access token
 * 
 * @param store Vuex store
 */
async function refreshAccessToken(store) {
    try {
        let response = await axios.post(`/api/refresh-token`, JSON.stringify({ refreshToken: store.getters['auth/getRefreshToken'] }));

        store.dispatch('auth/updateTokens', { accessToken: response.data.accessToken, refreshToken: response.data.refreshToken });

        return response.data;
    } catch (error) {
        throw Error;
    }
}

/**
 * Login admin
 * 
 * @param {string} a_id Admin access id from admin app
 */
async function loginAdmin(a_id) {
    try {
        return await axios.post(`/api/login-admin`, JSON.stringify({ a_id }));
    } catch (error) {
        return error.response;
    }
}

module.exports.login = login;
module.exports.refreshAccessToken = refreshAccessToken;
module.exports.loginAdmin = loginAdmin;

