import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import LoginAdmin from '../views/LoginAdmin.vue'
import Logout from '../views/Logout.vue'
import ResetPassword from '../views/ResetPassword.vue'
import SignUp from '../views/SignUp.vue'
import Step1 from '../views/Step1.vue'
import Step2 from '../views/Step2.vue'
import Step3 from '../views/Step3.vue'
import Step4 from '../views/Step4.vue'
import store from '../store';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: to => {
            if(to.query.a_id){
                return { path: '/login-admin', query: to.query }
            } else {
                return { path: '/step1' }
            }
        },
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/login-admin',
        name: 'login-admin',
        component: LoginAdmin,
        meta: {
            requiresA_IDParam: true,
        },
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
        props: true,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: SignUp,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/step1',
        name: 'step1',
        component: Step1,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/step2',
        name: 'step2',
        component: Step2,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/step3',
        name: 'step3',
        component: Step3,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/step4',
        name: 'step4',
        component: Step4,
        meta: {
            requiresAuth: true,
        },
    },
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: () => {
        return { x: 0, y: 0 }
    }
});

router.beforeResolve((to, from, next) => {
    
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (!store.getters['auth/getIsLoggedIn']) {
            next('/login');
        } else {
            next();
        }
        return;
    }

    if(to.matched.some((record) => record.meta.requiresA_IDParam)) {
        if(to.query.a_id)
            next();
        else {
            next('/');
        }
        return;
    }

    next();
})

export default router;