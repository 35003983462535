<template>
  <div id="app" class="fluid">
    <div id="fullPage">
      <LAHeader/>
      <div id="main-wrapper" class="fluid">
        <div class="container master-container">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LAHeader from "./components/LAHeader.vue";

export default {
  name: "App",
  data() {
    return {
      value: "World"
    };
  },
  components: {
    LAHeader,
  }
};
</script>

<style lang="scss" >
  @import "./assets/styles/style.scss";
</style>
