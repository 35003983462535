<template>
  <div>
    <progress-bar :step="3"></progress-bar>

    <b-row>
      <b-col class="details-container" md="12">
        <h1>{{ $t("review-submit-label") }}</h1>
        <div class="cust-number" v-if="!allDealersUpdate">{{$t('customer-number')}}: {{dealerDetails.customerNumber}}</div>
        <div class="cust-number" v-if="allDealersUpdate">{{$t('common-owner-number')}}: {{commonOwner}}</div>
        <div class="section-divider"></div>

        <b-row>
            <b-col md="6">
                <div class="review-section">
                    <div class="interior-header">{{$t('dealer-information-label')}}</div> 

                    <div>
                        <p>
                            <strong>{{$t('store-name')}}:</strong> {{dealerDetails.common.storeName}}
                            <br/>
                            <strong>{{$t('phone-number')}}:</strong> {{dealerDetails.common.telephoneNumber}}
                        </p>
                    </div>

                    <div>
                        <b-icon icon="check2" v-if="dealerDetails.common.displayOnConsumer"></b-icon>
                        <b-icon icon="x" v-if="!dealerDetails.common.displayOnConsumer"></b-icon>
                        {{$t('display-on-consumer')}}
                    </div>
                    <div>
                        <b-icon icon="check2" v-if="dealerDetails.common.displayOnCommercial"></b-icon>
                        <b-icon icon="x" v-if="!dealerDetails.common.displayOnCommercial"></b-icon>
                        {{$t('display-on-commercial')}}
                    </div>
                    <div>
                        <b-icon icon="check2" v-if="dealerDetails.common.displayOnOtr"></b-icon>
                        <b-icon icon="x" v-if="!dealerDetails.common.displayOnOtr"></b-icon>
                        {{$t('display-on-otr')}}
                    </div>
                </div>

                <div class="review-section">
                    <div class="interior-header">{{$t('website-social-label')}}</div> 

                    <p>
                        <strong>{{$t('website-url-label')}}:</strong> {{dealerDetails.social.website}}
                    </p>

                    <p>
                        <strong>{{$t('social-url-label')}}:</strong>
                    </p>

                    <p>
                        <img src="../assets/images/icon-fb.png" class="social-img">
                        <template v-if="dealerDetails.social.fbUrl === 'Not used'">{{$t('not-used-label')}}</template>
                        <template v-else>{{dealerDetails.social.fbUrl}}</template>
                        </p>

                        <p>
                        <img src="../assets/images/icon-instagram.png" class="social-img">
                        <template v-if="dealerDetails.social.instUrl === 'Not used'">{{$t('not-used-label')}}</template>
                        <template v-else>{{dealerDetails.social.instUrl}}</template>
                    </p>

                    <p>
                        <img src="../assets/images/icon-gplus.png" class="social-img" width="25px" height="25px" >
                        <template v-if="dealerDetails.social.gUrl === 'Not used'">{{$t('not-used-label')}}</template>
                        <template v-else>{{dealerDetails.social.gUrl}}</template>
                    </p>

                    <p>
                        <img src="../assets/images/icon-twitter.png" class="social-img">
                        <template v-if="dealerDetails.social.twitterUrl === 'Not used'">{{$t('not-used-label')}}</template>
                        <template v-else>{{dealerDetails.social.twitterUrl}}</template>
                    </p>

                    <p>
                        <img src="../assets/images/icon-yelp.png" class="social-img">
                        <template v-if="dealerDetails.social.yelpUrl === 'Not used'">{{$t('not-used-label')}}</template>
                        <template v-else>{{dealerDetails.social.yelpUrl}}</template>
                    </p>
                </div>

                <div class="review-section">
                    <div class="interior-header">{{$t('store-hours-label')}}</div> 

                    <p>
                        <strong>{{$t('monday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.mon === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.mon}}
                            <template v-if="dealerDetails.storeHours.monAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.monAdd}}
                            </template>
                        </template>
                    </p>
                    <p>
                        <strong>{{$t('tuesday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.tue === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.tue}}
                            <template v-if="dealerDetails.storeHours.tueAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.tueAdd}}
                            </template>
                        </template>
                    </p>
                    <p>
                        <strong>{{$t('wednesday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.wed === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.wed}}
                            <template v-if="dealerDetails.storeHours.wedAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.wedAdd}}
                            </template>
                        </template>
                    </p>
                    <p>
                        <strong>{{$t('thursday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.thu === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.thu}}
                            <template v-if="dealerDetails.storeHours.thuAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.thuAdd}}
                            </template>
                        </template>
                    </p>
                    <p>
                        <strong>{{$t('friday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.fri === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.fri}}
                            <template v-if="dealerDetails.storeHours.friAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.friAdd}}
                            </template>
                        </template>
                    </p>
                    <p>
                        <strong>{{$t('saturday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.sat === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.sat}}
                            <template v-if="dealerDetails.storeHours.satAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.satAdd}}
                            </template>
                        </template>
                    </p>
                    <p>
                        <strong>{{$t('sunday')}}: </strong>
                        <template v-if="dealerDetails.storeHours.sun === 'CLOSED'"><span class="text-uppercase">{{$t('closed')}}</span></template>
                        <template v-else>
                            {{dealerDetails.storeHours.sun}}
                            <template v-if="dealerDetails.storeHours.sunAdd">
                                <span class="text-uppercase">{{$t('and')}} </span> {{dealerDetails.storeHours.sunAdd}}
                            </template>
                        </template>
                    </p>
                </div>
            </b-col>

            <b-col md="6">
                <div class="review-section">
                    <div class="interior-header">{{$t('amenities-offerings-label')}}</div> 

                    <ul>
                        <li v-if="dealerDetails.offerings.freeGenCheckup"> {{$t('free-general-label')}}</li>
                        <li v-if="dealerDetails.offerings.vipClientCard"> {{$t('vip-client-card-label')}}</li>
                        <li v-if="dealerDetails.offerings.snacks"> {{$t('snacks-label')}}</li>
                        <li v-if="dealerDetails.offerings.beverages"> {{$t('beverages-label')}}</li>
                        <li v-if="dealerDetails.offerings.coffeeStation"> {{$t('coffee-station-label')}}</li>
                        <li v-if="dealerDetails.offerings.pickUpSvc"> {{$t('vehicle-service-label')}}</li>
                        <li v-if="dealerDetails.offerings.wifi"> {{$t('free-wifi-label')}}</li>
                    </ul>
                </div>

                <div class="review-section">
                    <div class="interior-header">{{$t('services')}}</div> 

                    <ul>
                        <li v-if="dealerDetails.services.airCond">{{$t('air-conditioning-label')}}</li>
                        <li v-if="dealerDetails.services.batteries">{{$t('batteries-label')}}</li>
                        <li v-if="dealerDetails.services.beltsHoses">{{$t('belts-hoses')}}</li>
                        <li v-if="dealerDetails.services.sysRepairs">{{$t('brake-systems-repair-label')}}</li>
                        <li v-if="dealerDetails.services.drivelines">{{$t('drivelines-label')}}</li>
                        <li v-if="dealerDetails.services.electricalSys">{{$t('electrical-systems')}}</li>
                        <li v-if="dealerDetails.services.engineDgnst">{{$t('engine-diagnostics-label')}}</li>
                        <li v-if="dealerDetails.services.fluidExchangeSvc">{{$t('fluid-exchange-services-label')}}</li>
                        <li v-if="dealerDetails.services.headlamps">{{$t('headlamps-bulbs-label')}}</li>
                        <li v-if="dealerDetails.services.coolantSys">{{$t('heating-coolant-systems-label')}}</li>
                        <li v-if="dealerDetails.services.mufflers">{{$t('mufflers-exhaust-label')}}</li>
                        <li v-if="dealerDetails.services.oilFilterSvc">{{$t('oil-lube-filter-services-label')}}</li>
                        <li v-if="dealerDetails.services.prvntMntncPkg">{{$t('preventative-maintenance-packages-label')}}</li>
                        <li v-if="dealerDetails.services.chocksStruts">{{$t('shocks-struts-label')}}</li>
                        <li v-if="dealerDetails.services.strtChrgSys">{{$t('starting-charging-systems-label')}}</li>
                        <li v-if="dealerDetails.services.stateInspections">{{$t('state-inspections-label')}}</li>
                        <li v-if="dealerDetails.services.suspSys">{{$t('steering-suspension-systems-label')}}</li>
                        <li v-if="dealerDetails.services.timingBelts">{{$t('timing-belts-label')}}</li>
                        <li v-if="dealerDetails.services.tireInstallation">{{$t('tire-installation-label')}}</li>
                        <li v-if="dealerDetails.services.tires">{{$t('tires')}}</li>
                        <li v-if="dealerDetails.services.tubes">{{$t('tubes')}}</li>
                        <li v-if="dealerDetails.services.protectors">{{$t('protectors')}}</li>
                        <li v-if="dealerDetails.services.retreads">{{$t('retreads')}}</li>
                        <li v-if="dealerDetails.services.mounting">{{$t('mounting')}}</li>
                        <li v-if="dealerDetails.services.dismounting">{{$t('dismounting')}}</li>
                        <li v-if="dealerDetails.services.tireRotation">{{$t('tire-rotation')}}</li>
                        <li v-if="dealerDetails.services.repairs">{{$t('repairs')}}</li>
                        <li v-if="dealerDetails.services.springs">{{$t('springs')}}</li>
                        <li v-if="dealerDetails.services.chockAbsorbers">{{$t('shock-absorbers-label')}}</li>
                        <li v-if="dealerDetails.services.terminals">{{$t('terminals')}}</li>
                        <li v-if="dealerDetails.services.tieRods">{{$t('tie-rods-label')}}</li>
                        <li v-if="dealerDetails.services.rubberStops">{{$t('stops-rubber-stops-label')}}</li>
                        <li v-if="dealerDetails.services.brakes">{{$t('brakes')}}</li>
                        <li v-if="dealerDetails.services.axleStraightening">{{$t('axle-straightening-label')}}</li>
                        <li v-if="dealerDetails.services.bearingExchange">{{$t('bearing-exchange-label')}}</li>
                        <li v-if="dealerDetails.services.engineRebuilt">{{$t('engine-rebuilt-label')}}</li>
                        <li v-if="dealerDetails.services.turbineRebuilt">{{$t('turbine-rebuilt-label')}}</li>
                        <li v-if="dealerDetails.services.alignment">{{$t('alignment')}}</li>
                        <li v-if="dealerDetails.services.balancing">{{$t('balancing')}}</li>
                        <li v-if="dealerDetails.services.perfTracking">{{$t('tire-performance-tracking-label')}}</li>
                        <li v-if="dealerDetails.services.bot">{{$t('bot-check-list')}}</li>
                        <li v-if="dealerDetails.services.vehicleWash">{{$t('vehicle-wash-label')}}</li>
                        <li v-if="dealerDetails.services.oil">{{$t('oil')}}</li>
                        <li v-if="dealerDetails.services.fluids">{{$t('fluids')}}</li>
                        <li v-if="dealerDetails.services.filters">{{$t('filters')}}</li>
                        <li v-if="dealerDetails.services.lubricants">{{$t('lubricants')}}</li>
                        <li v-if="dealerDetails.services.belts">{{$t('belts')}}</li>
                        <li v-if="dealerDetails.services.hoses">{{$t('hoses')}}</li>
                        <li v-if="dealerDetails.services.cargoTarp">{{$t('cargo-tarp-label')}}</li>
                        <li v-if="dealerDetails.services.hopes">{{$t('hopes')}}</li>
                        <li v-if="dealerDetails.services.lashingStraps">{{$t('lashing-straps-label')}}</li>
                        <li v-if="dealerDetails.services.reflectiveBands">{{$t('reflective-bands-label')}}</li>
                        <li v-if="dealerDetails.services.commBatteries">{{$t('batteries-label')}}</li>
                        <li v-if="dealerDetails.services.wheels">{{$t('wheels')}}</li>
                        <li v-if="dealerDetails.services.wiperBlades">{{$t('wiper-blades')}}</li>
                        <li v-if="dealerDetails.services.jack">{{$t('jack')}}</li>
                        <li v-if="dealerDetails.services.lamps">{{$t('lamps')}}</li>
                        <li v-if="dealerDetails.services.accessories">{{$t('accessories')}}</li>
                    </ul>
                </div>

                <div class="review-section">
                    <div class="interior-header">{{$t('credit-cards-accepted-title')}}:</div>

                    <ul>
                        <li v-if="dealerDetails.offerings.crdMastercard">
                            <img src="../assets/images/creditcards/mastercard.png" alt="Mastercard credit card icon"> Mastercard
                        </li>
                        <li v-if="dealerDetails.offerings.crdVisa">
                            <img src="../assets/images/creditcards/visa.png" alt="Visa credit card icon"> Visa
                        </li>
                        <li v-if="dealerDetails.offerings.crdAmrcnExpress">
                            <img src="../assets/images/creditcards/american.png" alt="American Express credit card icon"> American Express
                        </li>
                        <li v-if="dealerDetails.offerings.crdDiscover">
                            <img src="../assets/images/creditcards/discover.png" alt="Discover credit card icon"> Discover
                        </li>
                    </ul>

                </div>

                <div class="review-section">
                    <div class="interior-header">{{$t('closed-dates-store-label')}}</div>

                    <div v-for="(closedDate, index) in dealerDetails.closedDates" :key="`closed-date-${index}`">
                        <div v-if="!closedDate.isDeleted">
                            {{closedDate.closedDate | formatDate}}
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>

        <b-row v-if="!allDealersUpdate">
            <b-col md="12">
                <div class="review-section">
                    <div class="interior-header">{{$t('location-on-map-title')}}</div>
                    <b-row>
                        <b-col md="6">
                            <p>
                                <strong>{{$t('street-address')}}:</strong> {{dealerDetails.location.streetAddress}}, {{dealerDetails.location.city}}
                            </p>

                            <div class="alert alert-warning" role="alert">{{$t('dealer-information-incorrect-hint')}}</div>

                            <div class="form-group">
                                <p>
                                    <strong>LAT:</strong> {{dealerDetails.location.lat}}
                                </p>

                                <p>
                                    <strong>LONG:</strong> {{dealerDetails.location.lng}}
                                </p>
                            </div>
                        </b-col>
                        <b-col md="6">
                            <GmapMap v-if="dealerDetails.location.lat"
                                :center="{lat:dealerDetails.location.lat, lng:dealerDetails.location.lng}"
                                :zoom="15"
                                style="width:100%;  height: 400px;"
                                :options="{
                                    zoomControl: true,
                                    mapTypeControl: false,
                                    scaleControl: false,
                                    streetViewControl: false,
                                    rotateControl: false,
                                    fullscreenControl: false,
                                    disableDefaultUi: false
                                }"
                            >
                                <GmapMarker
                                    :position="{lat:parseFloat(dealerDetails.location.lat), lng:parseFloat(dealerDetails.location.lng)}"
                                />
                            </GmapMap>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <div class="text-center">
            <b-button class="gy-btn" v-on:click="toStep4" >
                {{ $t("submit-dealer-profile-label") }} <b-icon icon="chevron-right"/>
            </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- Error modal-->
    <GYModal
      :force-open="showErrorModal"
      header="Error"
      :header-close-button="true"
      @close="onCloseErrorModal"
    >
      <!-- Default content slot -->
      <div class="d-block modal-text">
        {{ errorMessage }}
      </div>
    </GYModal>

    <!-- Loading modal-->
    <GYModal
      :force-open="showLoadingModal"
      :header="$t('loading-title')"
      :hide-footer="true"
    >
      <!-- Default content slot -->
      <div class="d-block modal-text">
        <div class="row">
          <div class="col-md-offset-3 col-md-1 loader-img">
            <img
              src="../assets/images/loading.gif"
              alt="loading"
              style="width: 48px; height: 48px"
            />
          </div>
          <div class="col-md-6 loader-text">
            <p>{{ $t("loading-message") }}</p>
          </div>
        </div>
      </div>
    </GYModal>
  </div>
</template>

<script>
const dealerService = require("../lib/services/dealer.service");
import ProgressBar from "../components/ProgressBar.vue";
import GYModal from "../components/GYModal.vue";

export default {
    data() {
        return {
            dealerDetails: {
                common: {},
                location: {},
                services: {},
                offerings: {},
                closedDates: [],
                social: {},
                storeHours: {}
            },
            commonOwner: "",

            showLoadingModal: false,
            showErrorModal: false,
            errorMessage: "",
        };
    },
    computed: {
        allDealersUpdate: function(){
            return this.$store.state.dealer.allDealersUpdate;
        }
    },
    methods: {
        toStep4: async function () {
            try {

                let response;
            
                this.showLoadingModal = true;
                if (this.allDealersUpdate) {
                    response = await dealerService.updateAllDealersDetails(this.dealerDetails);
                } else {
                    response = await dealerService.updateDealerDetails(this.dealerDetails.customerNumber, this.dealerDetails.countryKey, this.dealerDetails);
                }
                this.showLoadingModal = false;

                switch (response.status) {
                    case 200:
                        this.$store.dispatch('dealer/setAfterSuccessfulUpdate', true);
                        this.$router.push('step4');
                        break;
                    case 403:
                    case 500:
                        this.errorMessage = response.data.message;
                        this.showErrorModal = true;
                        break;
                }
            } catch (error) {
                console.error(error);
            }
        },
        onCloseErrorModal: function () {
            this.showErrorModal = false;
        },
    },
    mounted: async function () {
        if(this.allDealersUpdate) {
            this.commonOwner = this.$store.getters['auth/getUser'].commonOwner;
        }

        this.dealerDetails = { ...this.$store.state.dealer.updatedDealerData };
    },
    filters: {
        formatDate: function (date) {
            let values = date.slice(0,10).split('-');
            return values[2] + '/' + values[1] + '/' + values[0];
        }
    },
    components: {
        ProgressBar,
        GYModal,
    },
    beforeRouteEnter(to, from, next) {
        // Enter reset-password route only if email prop is not empty
        next(vm => {
            if(!vm.$store.state.dealer.updatedDealerData){
                vm.$router.push({path: '/step1'});
            }
        })
    },
};
</script>

<style scoped>
.details-container{
    padding: 0px 60px;
}

p {
    margin-bottom: 5px;
}

.social-img{
    margin-right: 10px;
}

ul {
    margin-bottom: 0px;
}
</style>