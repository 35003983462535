<template>
  <b-form-select v-model="selectedLang" :options="langList" @change="langChange" size="sm"></b-form-select>
</template>

<script>

export default {
  data() {
    return {
      selectedLang: this.$i18n.locale,
      langList: [ 
        {value: 'en', text: 'English'},
        {value: 'es', text: 'Spanish'},
        {value: 'pt', text: 'Portuguese'}
      ]
    }
  },
  computed: {
  },
  methods: {
    async langChange() {
      this.$store.dispatch("i18n/setSelectedLang", this.selectedLang);
      this.$i18n.locale = this.selectedLang;
    }
  }
};

</script>

<style scoped>

</style>

