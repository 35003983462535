<template>
  <div>
     <div class="row justify-content-md-center">
      <div class="col-md-4 col-md-offset-2 pwd-rules-container d-none d-lg-block">
        <h2>{{$t('password')}}</h2>
        <ul>
            <li>{{$t('char-range-rule')}}</li>
            <li>{{$t('spaces-rule')}}</li>
            <li>{{$t('spec-char-rule')}}</li>
            <li>{{$t('consecutive-char-rule')}}</li>
            <li>{{$t('begin-number-rule')}}</li>
            <li>{{$t('email-rule')}}</li>
            <li>{{$t('full-name-rule')}}</li>
            <li>{{$t('password-rule')}}</li>
            <li>{{$t('last-five-passwords-rule')}}</li>
            <li>{{$t('num-digit-rule')}}</li>
        </ul>
      </div>
      <div class="col-md-4">
        
        <b-card
            header-tag="header"
            header-class="gy-card-header"
            footer-tag="footer"
        >
            <template #header>
                {{$t('reset-password')}}
            </template>

            <div class="alert alert-success" role="alert">
                <b-icon icon="check-circle-fill" variant="success"></b-icon>
                <span> {{$t('temp-password-has-been-sent')}}</span>
            </div>

            <b-form @submit.prevent="resetPassword">
                <b-form-group
                    id="input-group-temp-password"
                    label-for="temp-password"
                >
                    <template #label>
                        <span style="color:red">*</span>{{$t('temp-password')}}
                    </template>

                    <b-form-input
                        id="temp-password"
                        type="password"
                        v-model="$v.resetForm.tempPassword.$model"
                        v-on:change="incorrectTempPassword = false"
                    ></b-form-input>
                    <span v-if="$v.resetForm.tempPassword.$error && !$v.resetForm.tempPassword.required" role="alert" class="danger">{{$t('enter-temp-password')}}</span>
                    <div v-if="incorrectTempPassword">
                        <span role="alert" class="danger">{{$t('incorrect-temp-password')}}</span>
                    </div>
                </b-form-group>

                <b-form-group
                    id="input-group-password"
                    label-for="password"
                >
                    <template #label>
                        <span style="color:red">*</span>{{$t('create-new-password')}}
                    </template>
                    
                    <b-form-input
                        id="password"
                        type="password"
                        v-model="$v.resetForm.password.$model"
                        v-on:change="passwordRulesError = false"
                    ></b-form-input>
                    <span v-if="$v.resetForm.password.$error && !$v.resetForm.password.required" role="alert" class="danger">{{$t('please-create-password')}}</span>
                    <span v-if="$v.resetForm.password.$error && !$v.resetForm.password.notSameAsTempPassword" role="alert" class="danger">{{$t('password-match-temp-one')}}</span>
                    <div v-if="passwordRulesError">
                        <span role="alert" class="danger">{{$t('password-not-match-rules')}}</span>
                    </div>
                </b-form-group>

                <b-form-group
                    id="input-group-confirm-password"
                    label-for="confirm-password"
                >
                    <template #label>
                        <span style="color:red">*</span>{{$t('confirm-password')}}
                    </template>
                    
                    <b-form-input
                        id="confirm-password"
                        type="password"
                        v-model="$v.resetForm.confirmPassword.$model"
                    ></b-form-input>
                    <span v-if="$v.resetForm.confirmPassword.$error && !$v.resetForm.confirmPassword.sameAsPassword" role="alert" class="danger">{{$t('password-unconfirmed')}}</span>
                </b-form-group>

                <b-button class="gy-btn" type="submit" :disabled="$v.resetForm.$invalid">
                    <div class="spinner-border spinner-border-sm" role="status" v-if="showLoadingSpinner">
                        <span class="sr-only">Loading...</span>
                    </div>
                    {{$t("set-new-password")}}
                </b-button>
            </b-form>
        </b-card>
      </div>
    </div>

    <!-- Error modal-->
    <GYModal
        :force-open="showErrorModal"
        header="Error"
        :header-close-button="true"
        @close="onCloseErrorModal"
        
    >
        <!-- Default content slot -->
        <div class="d-block modal-text">
            {{errorMessage}}
        </div>
    </GYModal>

  </div>
</template>

<script>
const userService = require("../lib/services/user.service");
const { required, sameAs, not } = require('vuelidate/lib/validators');
import GYModal from '../components/GYModal.vue';
const constants = require('../constants');

export default {
    props: ['email'],
    data() {
        return {
            resetForm: {
                tempPassword: "",
                password: "",
                confirmPassword: ""
            },

            incorrectTempPassword: false,
            passwordRulesError: false,

            showLoadingSpinner: false,
            showErrorModal: false,
            errorMessage: "",
        };
    },
    validations: {
        resetForm: {
            tempPassword: {
                required
            },
            password: {
                required,
                notSameAsTempPassword: not(sameAs('tempPassword'))
            },
            confirmPassword: {
                sameAsPassword: sameAs('password')
            }
        },
    },
    methods: {
        resetPassword: async function () {
            this.$v.resetForm.$touch();

            if (!this.$v.resetForm.$invalid) {
                this.incorrectTempPassword = false;
                this.passwordRulesError = false;

                this.showLoadingSpinner = true;
                let response = await userService.resetPassword(this.email, this.resetForm.tempPassword, this.resetForm.password);
                this.showLoadingSpinner = false;

                switch (response.status) {
                    case 200:
                        this.$router.push({ name: 'login' })
                        break;
                    case 400:
                        switch (response.data.type) {
                            case constants.WRONG_TEMP_PASSWORD_TYPE:
                                this.incorrectTempPassword = true;
                                break;
                            case constants.PASSWORD_RULES_ERROR_TYPE:
                                this.passwordRulesError = true;  
                        }
                        break;
                    case 404:
                    case 500:
                        this.errorMessage = response.data.message;
                        this.showErrorModal = true;
                        break;
                }
            }
        },
        onCloseErrorModal: function () {
            this.showErrorModal = false;
        },
    },
    components: {
        GYModal,
    },
    beforeRouteEnter(to, from, next) {
        // Enter reset-password route only if email prop is not empty
        next(vm => {
            if(!vm.email){
                vm.$router.push({path: '/login'})
            }
        })
    },
};

</script>

<style scoped>
td.text-center {
  width: 73px;
}

.account-success-container .success-text {
  font-size: 28px;
}
.comm-services-block {
  margin: 20px 0;
}
.comm-services-header {
  font-weight: bold;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .navbar-default .navbar-nav > li {
    padding-left: 0;
    margin-left: 40px;
  }
  .pwd-rules-container {
    padding-right: 50px;
    padding-top: 60px;
  }
  .account-success-container {
    margin-bottom: 50px;
  }
}

td.text-center {
  width: 73px;
}

label.closed {
  border-left: 1px solid lightgray;
  padding-left: 10px;
  margin-left: 10px;
  margin-right: 20px;
}
</style>