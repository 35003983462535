<template>
  <div>
    <b-modal v-model="isOpen" :title="header" :class="modalClass" @hide="handleClose" header-class="modal-header" :hide-footer="hideFooter"> <!-- hide-footer>-->
        <template v-slot:modal-header v-if="!headerCloseButton">
            <!-- Header -->
            <slot name="header"> 
                <!-- Header from props -->
                <div class="header">
                    {{ header }}
                </div>
            </slot>
        </template>
        
        <template v-slot:default>
            <!-- Content title from props -->
            <div class="contentModal" v-if="contentTitle">
                <p class="text-center font-weight-bold header-large">
                    {{ contentTitle }}
                </p>
            </div>

            <!-- Content -->
            <slot>
                <div class="d-block text-center modal-text">
                    <p>
                        {{content}} <!-- Content from props -->
                    </p>
                </div>
            </slot>
        </template>
        <template v-slot:modal-footer>
            <!-- Footer -->
            <slot name="footer">
                <!-- Default footer with OK button that simply close modal -->
                <div class="text-center">
                    <b-button class="btn-option" v-on:click="closeDialog(true)">{{closeButtonText}}</b-button>
                </div>
            </slot>
        </template>
    </b-modal>    
  </div>
</template>


<script>
    export default {
      name: 'Modal',
      props: {
          forceOpen: {
              type: Boolean,
              default: false,
          },
          header: {
              type: String,
              default: '',
          },
          contentTitle: {
              type: String,
              default: '',
          },
          content: {
              type: String,
              default: '',
          },
          headerCloseButton: {
              type: Boolean,
              default: false,
          },
          closeButtonText: {
              type: String,
              default: 'OK',
          },
          hideFooter: {
              type: Boolean,
              default: false,
          },
      },
      data() {
          return {
              isOpen: false,
          };
      },
      computed: {
        modalClass: function() {
          return 'success-modal-header'
        },
      },
      watch: {
        forceOpen(value) {
            this.isOpen = value;
        },
      }, 
      methods: {
        closeDialog(status) {
            this.isOpen = false;

            this.$emit('close', status);
        },
        handleClose(bvModalEvt) {
            if(bvModalEvt.trigger === 'backdrop' || bvModalEvt.trigger === 'esc' || bvModalEvt.trigger === 'headerclose') {
                bvModalEvt.preventDefault();
            }
            this.closeDialog(true);
        }
      },       
    };
</script>

<style scoped>
    ::v-deep .modal-header {
        background-color: #274ba7;
        color: #fff;
    }

    .modal-footer.success-modal-footer {
        justify-content: center;
    }

    .modal-body, .modal-text {
        font-family: 'BarlowCondensed-SemiBold', sans-serif;
    }

    .modal-body .header-large {
        font-size: 28px;
    }

    .header {
        width: 100%;
        display: flex;
        align-items: center; /* align text vertical */
    }
    
</style>