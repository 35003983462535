<template>
  <div class="expansion-panel-container">
    <div class="expansion-panel" v-bind:class="{ toggled: show }">
      <div class="layout-row expansion-panel__header" v-on:click="show = !show">
        <div class="expansion-panel__title layout-column">
            <slot name="title"></slot>
        </div>
        <div class="expansion-panel__arrow-down layout-column">
            <b-icon icon="chevron-up"></b-icon>
        </div>
      </div>

      <transition name="slide" >
        <div v-show="show">
          <div class="expansion-panel__content">
            <div class="panel-body">
              <slot name="content"></slot>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      hideContentOnStart: {
        required: false,
        default: false,
        type: Boolean
      }
    },
    data() {
      return {
        show: !this.hideContentOnStart
      }
    }
  }
</script>

<style scoped lang="scss">
  $radius: 4px;
  $padding: 5px 10px;

  .expansion-panel-container {
    display: block;
  }

  .slide-enter-active {
    -moz-transition-duration: 0.6s;
    -webkit-transition-duration: 0.6s;
    -o-transition-duration: 0.6s;
    transition-duration: 0.6s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.5s;
    -webkit-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 1000px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }

  .layout-row {
    display: flex;
    flex-direction: row;
  }

  .layout-column {
    display: flex;
    flex-direction: column;
  }

  .expansion-panel {
    flex-wrap: wrap;
    margin-top: 10px; 
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    color: #212121;
    transition: all;
    font-size: inherit;
    line-height: 1.5;
    background: #fff;
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }

  .expansion-panel__title {
    flex: 1 1 60%;
    font-weight: normal;
  }

  .expansion-panel__header {
    padding: $padding;
    cursor: pointer;
    outline: 0;
    color: #FFFFFF;
    font-size: 26px;
    font-weight: normal;
    background-color: #1a263c;
    border-top-right-radius: $radius;
    border-top-left-radius: $radius;
  }

  .expansion-panel__content {
    flex: 1 1 100%;
    background: #fff;
    padding: 15px;
    outline: 0;
  }

  .panel-body{
    padding: 15px;
  }

  .expansion-panel__arrow-down {
    flex: 1 1 5%;
    align-items: flex-end;
  }

  .expansion-panel__arrow-down svg {
    transition: transform 0.4s;
    margin: 5px;
  }

  .toggled .expansion-panel__arrow-down svg {
    transform: rotate(180deg);
  }

</style>