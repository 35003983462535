import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';
import i18n from './modules/i18n.store';
import auth from './modules/auth.store';
import dealer from './modules/dealer.store';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        i18n,
        auth,
        dealer,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : [],
})