const timeValues = ["1:00", "1:30", "2:00", "2:30", "3:00", "3:30", "4:00", "4:30", "5:00", "5:30", "6:00", "6:30",
    "7:00", "7:30", "8:00", "8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30"];
const ampmValues = ["AM", "PM"];

/**
    * Gets separate time values from common store hours string
    * @param val - store hours string
*/
function getTime(val) {
    let reg = new RegExp('[0-9]{1,2}[:]{1}[0-9]{1,2}','g');
    return val.match(reg);
}

/**
  * Gets separate interval values from common store hours string
  * @param val - store hours string
*/
function getIntervals(val) {
    let reg = new RegExp('AM|PM','g');
    return val.match(reg);
}

/**
  * Parse storeHours object values
  * @param storeHours - store hours object from API
*/
function parseStoreHours(storeHours) {

    let result = {};

    if(storeHours.mon) {
        result.monIsActive = true;
        if(storeHours.mon === "CLOSED"){
            result.monIsClosed = true;
        } else {
            let monTime = getTime(storeHours.mon);
            let monAMPM = getIntervals(storeHours.mon);

            result.monStartTime = monTime[0];
            result.monStartAMPM = monAMPM[0];
            result.monEndTime = monTime[1];
            result.monEndAMPM = monAMPM[1];

            if(storeHours.monAdd) {
                result.monHasAddHours = true;

                let monAddTime = getTime(storeHours.monAdd);
                let monAddAMPM = getIntervals(storeHours.monAdd);

                result.monAddStartTime = monAddTime[0];
                result.monAddStartAMPM = monAddAMPM[0];
                result.monAddEndTime = monAddTime[1];
                result.monAddEndAMPM = monAddAMPM[1];
            }
        }
    }

    if(storeHours.tue) {
        result.tueIsActive = true;
        if(storeHours.tue === "CLOSED"){
            result.tueIsClosed = true;
        } else {
            let tueTime = getTime(storeHours.tue);
            let tueAMPM = getIntervals(storeHours.tue);

            result.tueStartTime = tueTime[0];
            result.tueStartAMPM = tueAMPM[0];
            result.tueEndTime = tueTime[1];
            result.tueEndAMPM = tueAMPM[1];

            if(storeHours.tueAdd) {
                result.tueHasAddHours = true;
                
                let tueAddTime = getTime(storeHours.tueAdd);
                let tueAddAMPM = getIntervals(storeHours.tueAdd);

                result.tueAddStartTime = tueAddTime[0];
                result.tueAddStartAMPM = tueAddAMPM[0];
                result.tueAddEndTime = tueAddTime[1];
                result.tueAddEndAMPM = tueAddAMPM[1];
            }
        }
    }

    if(storeHours.wed) {
        result.wedIsActive = true;
        if(storeHours.wed === "CLOSED"){
            result.wedIsClosed = true;
        } else {
            let wedTime = getTime(storeHours.wed);
            let wedAMPM = getIntervals(storeHours.wed);

            result.wedStartTime = wedTime[0];
            result.wedStartAMPM = wedAMPM[0];
            result.wedEndTime = wedTime[1];
            result.wedEndAMPM = wedAMPM[1];

            if(storeHours.wedAdd) {
                result.wedHasAddHours = true;
                
                let wedAddTime = getTime(storeHours.wedAdd);
                let wedAddAMPM = getIntervals(storeHours.wedAdd);

                result.wedAddStartTime = wedAddTime[0];
                result.wedAddStartAMPM = wedAddAMPM[0];
                result.wedAddEndTime = wedAddTime[1];
                result.wedAddEndAMPM = wedAddAMPM[1];
            }
        }
    }

    if(storeHours.thu) {
        result.thuIsActive = true;
        if(storeHours.thu === "CLOSED"){
            result.thuIsClosed = true;
        } else {
            let thuTime = getTime(storeHours.thu);
            let thuAMPM = getIntervals(storeHours.thu);

            result.thuStartTime = thuTime[0];
            result.thuStartAMPM = thuAMPM[0];
            result.thuEndTime = thuTime[1];
            result.thuEndAMPM = thuAMPM[1];

            if(storeHours.thuAdd) {
                result.thuHasAddHours = true;
                
                let thuAddTime = getTime(storeHours.thuAdd);
                let thuAddAMPM = getIntervals(storeHours.thuAdd);

                result.thuAddStartTime = thuAddTime[0];
                result.thuAddStartAMPM = thuAddAMPM[0];
                result.thuAddEndTime = thuAddTime[1];
                result.thuAddEndAMPM = thuAddAMPM[1];
            }
        }
    }

    if(storeHours.fri) {
        result.friIsActive = true;
        if(storeHours.fri === "CLOSED"){
            result.friIsClosed = true;
        } else {
            let friTime = getTime(storeHours.fri);
            let friAMPM = getIntervals(storeHours.fri);

            result.friStartTime = friTime[0];
            result.friStartAMPM = friAMPM[0];
            result.friEndTime = friTime[1];
            result.friEndAMPM = friAMPM[1];

            if(storeHours.friAdd) {
                result.friHasAddHours = true;
                
                let friAddTime = getTime(storeHours.friAdd);
                let friAddAMPM = getIntervals(storeHours.friAdd);

                result.friAddStartTime = friAddTime[0];
                result.friAddStartAMPM = friAddAMPM[0];
                result.friAddEndTime = friAddTime[1];
                result.friAddEndAMPM = friAddAMPM[1];
            }
        }
    }

    if(storeHours.sat) {
        result.satIsActive = true;
        if(storeHours.sat === "CLOSED"){
            result.satIsClosed = true;
        } else {
            let satTime = getTime(storeHours.sat);
            let satAMPM = getIntervals(storeHours.sat);

            result.satStartTime = satTime[0];
            result.satStartAMPM = satAMPM[0];
            result.satEndTime = satTime[1];
            result.satEndAMPM = satAMPM[1];

            if(storeHours.satAdd) {
                result.satHasAddHours = true;
                
                let satAddTime = getTime(storeHours.satAdd);
                let satAddAMPM = getIntervals(storeHours.satAdd);

                result.satAddStartTime = satAddTime[0];
                result.satAddStartAMPM = satAddAMPM[0];
                result.satAddEndTime = satAddTime[1];
                result.satAddEndAMPM = satAddAMPM[1];
            }
        }
    }

    if(storeHours.sun) {
        result.sunIsActive = true;
        if(storeHours.sun === "CLOSED"){
            result.sunIsClosed = true;
        } else {
            let sunTime = getTime(storeHours.sun);
            let sunAMPM = getIntervals(storeHours.sun);

            result.sunStartTime = sunTime[0];
            result.sunStartAMPM = sunAMPM[0];
            result.sunEndTime = sunTime[1];
            result.sunEndAMPM = sunAMPM[1];

            if(storeHours.sunAdd) {
                result.sunHasAddHours = true;
                
                let sunAddTime = getTime(storeHours.sunAdd);
                let sunAddAMPM = getIntervals(storeHours.sunAdd);

                result.sunAddStartTime = sunAddTime[0];
                result.sunAddStartAMPM = sunAddAMPM[0];
                result.sunAddEndTime = sunAddTime[1];
                result.sunAddEndAMPM = sunAddAMPM[1];
            }
        }
    }

    return { ...defaultStoreHoursObject, ...result};
}

/**
  * Compile storeHours object values
  * @param storeHours - store hours edited object 
*/
function compileStoreHours(storeHours) {

    let result = {
        mon: storeHours.monIsClosed ? "CLOSED" : storeHours.monStartTime + storeHours.monStartAMPM + ' - ' + storeHours.monEndTime + storeHours.monEndAMPM,
        monAdd: storeHours.monHasAddHours ? storeHours.monAddStartTime + storeHours.monAddStartAMPM + ' - ' + storeHours.monAddEndTime + storeHours.monAddEndAMPM : null,
        tue: storeHours.tueIsClosed ? "CLOSED" : storeHours.tueStartTime + storeHours.tueStartAMPM + ' - ' + storeHours.tueEndTime + storeHours.tueEndAMPM,
        tueAdd: storeHours.tueHasAddHours ? storeHours.tueAddStartTime + storeHours.tueAddStartAMPM + ' - ' + storeHours.tueAddEndTime + storeHours.tueAddEndAMPM : null,
        wed: storeHours.wedIsClosed ? "CLOSED" : storeHours.wedStartTime + storeHours.wedStartAMPM + ' - ' + storeHours.wedEndTime + storeHours.wedEndAMPM,
        wedAdd: storeHours.wedHasAddHours ? storeHours.wedAddStartTime + storeHours.wedAddStartAMPM + ' - ' + storeHours.wedAddEndTime + storeHours.wedAddEndAMPM : null,
        thu: storeHours.thuIsClosed ? "CLOSED" : storeHours.thuStartTime + storeHours.thuStartAMPM + ' - ' + storeHours.thuEndTime + storeHours.thuEndAMPM,
        thuAdd: storeHours.thuHasAddHours ? storeHours.thuAddStartTime + storeHours.thuAddStartAMPM + ' - ' + storeHours.thuAddEndTime + storeHours.thuAddEndAMPM : null,
        fri: storeHours.friIsClosed ? "CLOSED" : storeHours.friStartTime + storeHours.friStartAMPM + ' - ' + storeHours.friEndTime + storeHours.friEndAMPM,
        friAdd: storeHours.friHasAddHours ? storeHours.friAddStartTime + storeHours.friAddStartAMPM + ' - ' + storeHours.friAddEndTime + storeHours.friAddEndAMPM : null,
        sat: storeHours.satIsClosed ? "CLOSED" : storeHours.satStartTime + storeHours.satStartAMPM + ' - ' + storeHours.satEndTime + storeHours.satEndAMPM,
        satAdd: storeHours.satHasAddHours ? storeHours.satAddStartTime + storeHours.satAddStartAMPM + ' - ' + storeHours.satAddEndTime + storeHours.satAddEndAMPM : null,
        sun: storeHours.sunIsClosed ? "CLOSED" : storeHours.sunStartTime + storeHours.sunStartAMPM + ' - ' + storeHours.sunEndTime + storeHours.sunEndAMPM,
        sunAdd: storeHours.sunHasAddHours ? storeHours.sunAddStartTime + storeHours.sunAddStartAMPM + ' - ' + storeHours.sunAddEndTime + storeHours.sunAddEndAMPM : null
    };

    return result;
}

const defaultStoreHoursObject = {
    monIsActive: false,
    monIsClosed: false,
    monHasAddHours: false,
    monStartTime: "7:30",
    monStartAMPM: "AM",
    monEndTime: "5:30",
    monEndAMPM: "PM",
    monAddStartTime: "1:30",
    monAddStartAMPM: "PM",
    monAddEndTime: "5:30",
    monAddEndAMPM: "PM",

    tueIsActive: false,
    tueIsClosed: false,
    tueHasAddHours: false,
    tueStartTime: "7:30",
    tueStartAMPM: "AM",
    tueEndTime: "5:30",
    tueEndAMPM: "PM",
    tueAddStartTime: "1:30",
    tueAddStartAMPM: "PM",
    tueAddEndTime: "5:30",
    tueAddEndAMPM: "PM",

    wedIsActive: false,
    wedIsClosed: false,
    wedHasAddHours: false,
    wedStartTime: "7:30",
    wedStartAMPM: "AM",
    wedEndTime: "5:30",
    wedEndAMPM: "PM",
    wedAddStartTime: "1:30",
    wedAddStartAMPM: "PM",
    wedAddEndTime: "5:30",
    wedAddEndAMPM: "PM",

    thuIsActive: false,
    thuIsClosed: false,
    thuHasAddHours: false,
    thuStartTime: "7:30",
    thuStartAMPM: "AM",
    thuEndTime: "5:30",
    thuEndAMPM: "PM",
    thuAddStartTime: "1:30",
    thuAddStartAMPM: "PM",
    thuAddEndTime: "5:30",
    thuAddEndAMPM: "PM",

    friIsActive: false,
    friIsClosed: false,
    friHasAddHours: false,
    friStartTime: "7:30",
    friStartAMPM: "AM",
    friEndTime: "5:30",
    friEndAMPM: "PM",
    friAddStartTime: "1:30",
    friAddStartAMPM: "PM",
    friAddEndTime: "5:30",
    friAddEndAMPM: "PM",

    satIsActive: false,
    satIsClosed: false,
    satHasAddHours: false,
    satStartTime: "7:30",
    satStartAMPM: "AM",
    satEndTime: "5:30",
    satEndAMPM: "PM",
    satAddStartTime: "1:30",
    satAddStartAMPM: "PM",
    satAddEndTime: "5:30",
    satAddEndAMPM: "PM",

    sunIsActive: false,
    sunIsClosed: false,
    sunHasAddHours: false,
    sunStartTime: "7:30",
    sunStartAMPM: "AM",
    sunEndTime: "5:30",
    sunEndAMPM: "PM",
    sunAddStartTime: "1:30",
    sunAddStartAMPM: "PM",
    sunAddEndTime: "5:30",
    sunAddEndAMPM: "PM",
}

module.exports.timeValues = timeValues;
module.exports.ampmValues = ampmValues;
module.exports.defaultStoreHoursObject = defaultStoreHoursObject;
module.exports.parseStoreHours = parseStoreHours;
module.exports.compileStoreHours = compileStoreHours;