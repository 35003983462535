import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import en from './assets/i18n/en.json';
import sp from './assets/i18n/sp.json';
import br from './assets/i18n/br.json';
import authInterceptor from './interceptors/auth.interceptor';
import Vuelidate from 'vuelidate';
import * as VueGoogleMaps from 'vue2-google-maps';


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// i18n
Vue.use(VueI18n);

// Forms validation
Vue.use(Vuelidate);

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBMVuxEBnKPww_y9mL3G2rzIOkAOfPfgtg',
    }
});

const messages = {
    'en': en,
    'es': sp,
    'pt': br,
};

const i18n = new VueI18n({
    locale: localStorage.getItem('ladealerprofileLanguage') || 'en', // set locale
    messages: messages
});

authInterceptor.initAuthInterceptor(store);

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
}).$mount('#app')
