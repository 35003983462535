const user = JSON.parse(localStorage.getItem('user'));

const state = user ? {
    status: { isLoggingIn: false, isLoggedIn: true }, user, admin: null
} : { 
    status: { isLoggingIn: false, isLoggedIn: false }, user: null, admin: null
};

// Getter functions
const getters = {
    getIsLoggingIn: state => {
        return state.status.isLoggingIn;
    },
    getIsLoggedIn: state => {
        return state.status.isLoggedIn;
    },
    getUser: state => {
        if(state.admin)
            return state.admin;
        else
            return state.user;
    },
    getAccessToken: state => {
        if(state.admin)
            return state.admin.accessToken;
        if(state.user)
            return state.user.accessToken;
        return null;
    },
    getRefreshToken: state => {
        if(state.user)
            return state.user.refreshToken;
        return null;
    },
    isAdmin: state => {
        return !!state.admin;
    }
}

// Actions 
const actions = {
    logout({ commit }) {
        localStorage.removeItem('user');
        commit('LOGOUT');
    },
    login({ commit }, user) {
        localStorage.setItem('user', JSON.stringify(user));

        commit('SET_USER', user);
        commit('SET_IS_LOGGED_IN', true);
    },
    updateTokens({ commit, state }, tokens) {
        commit('SET_ACCESS_TOKEN', tokens.accessToken);
        commit('SET_REFRESH_TOKEN', tokens.refreshToken);
        localStorage.setItem('user', JSON.stringify(state.user));
    },
    loginAdmin({ commit }, admin) {
        localStorage.removeItem('user');

        commit('SET_ADMIN_USER', admin);
        commit('SET_IS_LOGGED_IN', true);
    },
}

// Mutations
const mutations = {
    SET_IS_LOGGING_IN(state, value) {
        state.status.isLoggingIn = value;
    },
    SET_IS_LOGGED_IN(state, value) {
        state.status.isLoggedIn = value;
    },
    SET_USER(state, value) {
        state.user = value;
    },
    SET_ACCESS_TOKEN(state, value) {
        state.user.accessToken = value;
    },
    SET_REFRESH_TOKEN(state, value) {
        state.user.refreshToken = value;
    },
    LOGOUT(state) {
        state.status.isLoggedIn = false;
        state.user = null;
        state.admin = null;
    },
    SET_ADMIN_USER(state, value) {
        state.admin = value;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}