<template>
  <div class="row">
    <div class="col-12 text-center">
      <h1 class="green">
        <b-icon-check-circle></b-icon-check-circle> {{$t('logged-out')}}</h1>
      <div class="cust-number">{{$t('logged-out-message')}}</div>
      <br>
      <div class="col-12 ">
        <div class="col-12">
          <b-button class="gy-btn" to="/login">{{$t('log-in')}}</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    data() {
        return {}
    },
    mounted: function (){
        this.$store.dispatch('auth/logout');
        this.$store.dispatch('dealer/resetDealerData');
    }
};

</script>

<style scoped>
    
</style>